import { pt } from "date-fns/locale";

export const I18N_STRINGS = {
  // header:
  home: { de: 'Navigation', en: 'Navigation', pt: 'navegação' },
  asset: { de: 'Asset', en: 'Asset', pt: 'Equipamento' },
  plant: { de: 'Anlage', en: 'Plant', pt: 'Fábrica' },
  // login page:
  create_account: { de: 'Ein Konto erstellen', en: 'Create an account', pt: 'Criar uma conta' },
  create_account_info: {
    de: 'Füllen Sie das folgende Formular aus, um Ihr Konto zu erstellen und Ihnen Zugang zu Loctite Pulse zu geben',
    en: 'Fill out the following form to check and approve your account and give you access to Loctite Pulse',
    pt: 'Preencha o formulário a seguir para verificar e aprovar sua conta e lhe dar',
  },
  username: { de: 'Benutzername', en: 'Username', pt:  'Nome de usuário' },
  password: { de: 'Passwort', en: 'Password', pt: 'senha' },
  email: { de: 'Email', en: 'Email', pt: 'Email' },
  login_failed: {
    de: 'Login-Daten sind falsch.',
    en: 'Login details are wrong.',
    pt: 'Os detalhes de login estão errados',
  },
  acc_blocked: {
    de: 'Dieser Account ist aktuell blockiert. \n Bitte kontaktieren Sie service@loctite-pulse.com, um ihren Account wieder freizuschalten.',
    en: 'The user account is currently blocked. \n Please contact service@loctite-pulse.com to have your account unblocked.',
    pt: 'A conta de usuário está bloqueada no momento. Entre em contato \n com service@loctite-pulse.com para desbloquear sua conta'
  },
  role_not_authorized: {
    de: 'Sie haben keine Rechte für den Zugriff auf diese Seite. Bitte wenden Sie sich an Ihren Administrator, damit er Ihnen den richtigen Zugang zum System gewähren kann.',
    en: 'You don´t have the rights to access this page. Please contact your administrator so they can grant you the right access to the system.',
    pt: 'Você não tem os direitos para acessar esta página. Entre em contato com o administrador para que ele lhe conceda o acesso correto ao sistema.',
  },
  login: { de: 'Anmeldung', en: 'Login', pt: 'Login' },
  enter_loctite: { de: 'Anmelden', en: 'Enter Loctite Pulse', pt: 'Inserir Loctite Pulse' },
  via_portal: { de: 'Über Portal anmelden', en: 'Login via Portal', pt: 'Faça login pelo portal' },
  msal_login: { de: 'MS Entra Anmeldung', en: 'MS Entra Login', pt: 'MS entra Login' },
  skip_login: { de: 'Anmeldung überspringen', en: 'Skip Login', pt: 'Ignorar login' },
  sso_conversion_prompt_title: {
    de: 'Single Sign-On Umwandlung',
    en: 'Single Sign-On Conversion',
    pt: 'Conversão de Single Sign-On',
  },
  sso_conversion_prompt_1: {
    de: "Sie haben bereits ein Konto bei uns. Möchten Sie es in ein Single Sign-On-Konto umwandeln? Dieser einmalige Prozess ermöglicht es Ihnen, sich mit Ihren Firmenkonto über die Schaltfläche 'Mit Microsoft anmelden' einzuloggen.",
    en: "You already have an account with us. Would you like to convert it to a Single Sign-On account? This one-time process allows you to log in using your company credentials via the 'Sign in with Microsoft' button.",
    pt: "Você já tem uma conta conosco. Gostaria de convertê-la para uma conta de Single Sign-On? Este processo único permite que você faça login usando suas credenciais corporativas através do botão 'Entrar com Microsoft'.",
  },
  sso_conversion_prompt_2: {
    de: 'Bitte beachten Sie, dass diese Änderung irreversibel ist und Sie sich anschließend nicht mehr mit Ihrem Benutzernamen und Passwort anmelden können.',
    en: 'Please note, this change is irreversible and you won’t be able to log in with your username and password.',
    pt: 'Observe que essa mudança é irreversível e você não poderá mais fazer login com seu nome de usuário e senha.',
  },
  sso_conversion_submit: {
    de: 'In SSO umwandeln',
    en: 'Convert to SSO',
    pt: 'Converter para SSO',
  },
  sso_conversion_cancel: {
    de: 'Abbrechen',
    en: 'Cancel',
    pt: 'Cancelar',
  },
  pw_change_disabled_due_sso: {
    de: 'Passwortfunktionen für SSO-Konten deaktiviert.',
    en: 'Password functions disabled for SSO accounts.',
    pt: 'Funções de senha desativadas para contas SSO.',
  },
  sign_up: {
    de: 'Noch keinen Account? Hier anmelden',
    en: 'No account yet? Sign up here',
    pt: 'Ainda não tem conta? Registre-se aqui',
  },
  already_account: {
    de: 'Ich habe bereits ein Konto, Log In',
    en: 'I already have an account, Log In',
    pt: 'Eu já tenho uma conta, faça login',
  },
  go_to_ia: { 
    de: 'Zum Installation Agent',
    en: 'Go to Installation Agent',
    pt: 'Ir para o agente de instalação',
  },
  forgot_password_button: {
    de: 'Passwort vergessen?',
    en: 'Forgot your password?',
    pt: 'Esqueceu sua senha?',
  },
  sign_in_with_microsoft: {
    de: 'Mit Microsoft anmelden',
    en: 'Sign in with Microsoft',
    pt: 'Entrar com Microsoft',
  },
  send_mail: {
    de: 'Email versenden',
    en: 'Send email',
    pt: 'Enviar email',
  },
  forgot_password_title: { de: 'Passwort vergessen?', en: 'Forgot password?', pt: 'Esqueci minha senha' },
  forgot_password_text: {
    de: 'Bitte geben Sie ihre Email-Adresse an und wir werden Ihnen Anweisungen zum Ändern Ihres Passworts zusenden.',
    en: "Enter your email address and we'll send you an email with instructions on how to set a new password.",
    pt: 'Digite seu endereço de e-mail e nós lhe enviaremos um e-mail com instruções sobre como definir uma nova senha.',
  },
  back_to_login: { de: 'Zurück zur Anmeldung', en: 'Back to login', pt: 'voltar ao login' },
  check_your_email: {
    de: 'Überprüfen Sie Ihre E-Mail',
    en: 'Check your email',
    pt: 'Verifique seu email',
  },
  check_your_email_generic: {
    de: 'Falls die von Ihnen eingegebene E-Mail-Adresse mit einem Konto auf unserer Plattform verbunden ist, haben wir Ihnen eine E-Mail mit Anweisungen zum Zurücksetzen Ihres Passworts geschickt. Bitte prüfen Sie Ihren Posteingang und Ihren Spam-Ordner auf eine E-Mail von uns.',
    en: "If the email address you entered is associated with an account on our platform, we've sent you an email with instructions on how to reset your password. Please check your inbox and spam folder for an email from us.",
    pt: "Se o endereço de e-mail que você digitou estiver associado a uma conta em nossa plataforma, enviamos um e-mail com instruções sobre como redefinir sua senha.Verifique se há um e-mail nosso em sua caixa de entrada e na pasta de spam.",
  },
  check_your_email1: {
    de: 'Wir haben Ihnen eine E-Mail geschickt an ',
    en: 'We have sent you an email to ',
    pt: 'Enviamos um e-mail para',
  },
  check_your_email2: {
    de: '. Bitte klicken Sie auf den Link, um ein neues Passwort zu wählen.',
    en: '. Please click on the link to choose a new password.',
    pt: 'Clique no link para escolher uma nova senha',
  },
  email_not_received: {
    de: 'Ich habe keine Email erhalten.',
    en: "I haven't received an email.",
    pt: 'Não recebi um e-mail',
  },
  pw_changed_success: {
    de: 'Ihr Passwort wurde erfolgreich geändert, Sie können sich jetzt anmelden.',
    en: 'Your password has been successfully changed, you can now login.',
    pt: 'Sua senha foi alterada com sucesso, agora você pode fazer login',
  },
  signed_up_success: {
    de: 'Anmeldeformular erfolgreich abgeschickt.',
    en: 'Registration form successfully submitted.',
    pt: 'Formulário de registro enviado com sucesso',
  },
  unknown_mail: {
    de: 'Unbekannte E-Mail Adresse',
    en: 'Unknown email address',
    pt: 'Endereço de e-mail desconhecido'
  },
  mail_send_error: {
    de: 'E-Mail konnte nicht versendet werden. Bitte versuchen Sie es später erneut.',
    en: 'Failed to send email. Please try again later.',
    pt: 'Falha ao enviar e-mail. Tente novamente mais tarde.'
  },
  // sign up:
  choose_password: { de: 'Wählen Sie Ihr Passwort', en: 'Choose password', pt:'escolha a senha' },
  repeat_password: { de: 'Passwort wiederholen', en: 'Repeat password', pt: 'Repetir a senha' },
  country: {
    de: 'Land, das Loctite Pulse Dienstleistungen erhält',
    en: 'Country to receive Loctite Pulse Services',
    pt: 'País que receberá os serviços Loctite Pulse',
  },
  uscountry: {
    de: 'For US and Canada please register',
    en: 'For US and Canada please register',
    pt: 'Para os US e Canadá, registre-se',
  },
  notuscountry: {
    de: 'For countries outside the US or Canada, please register',
    en: 'For countries outside the US or Canada, please register',
    pt: 'Para países fora dos EUA ou Canadá, registre-se',
  },
  select_country: { de: 'Land auswählen', en: 'Select Country', pt: 'Selecionar país' },
  select_customer: { de: 'Kunde auswählen', en: 'Select Customer', pt: 'Selecionar cliente' },
  customerentity: { de: 'Kundenunternehmen', en: 'Customer Entity', pt: 'Divisão' },
  customer: { de: 'Kunde', en: 'Customer', pt: 'Cliente' },
  submit_form: { de: 'Formular abschicken', en: 'Submit form', pt: 'Enviar formulário' },
  signup_failed: { de: 'Registrierung fehlgeschlagen', en: 'Signup failed', pt: 'Falha no registro' },
  //system maintenance
  system_maintenance: { de: 'System maintenance', en: 'System maintenance', pt: 'manutenção do sistema' },
  maintenance_message: {
    de: 'We are doing maintenance on Loctite Pulse, some features may not be available or look the same.',
    en: 'We are doing maintenance on Loctite Pulse, some features may not be available or look the same.',
    pt: 'Estamos fazendo manutenção no Loctite Pulse, alguns recursos podem não estar disponíveis ou ter a mesma aparência.',
  },
  system_outage: { de: 'Systemausfall', en: 'System downtime', pt: 'Sistema inativo' },
  outage_message: {
    de: 'Aufgrund eines Ausfalls, ist diese App nicht vollständig zugänglich und es können nicht alle Daten aktualisiert werden. Wir entschuldigen uns für die Unannehmlichkeiten.\n\nBitte haben Sie Geduld mit uns, während wir mit höchster Priorität daran arbeiten, den Service wieder online zu bringen. Wir danken Ihnen für Ihre Geduld und Ihr Verständnis.',
    en: 'Due to a current outage, this app is not completely accessible and not all data can be updated. We apologize for the inconvenience.\n\nPlease bear with us as we work with highest priority to bring the service back online. Thank you for your patience and understanding.',
    pt: 'Devido a uma interrupção atual, este aplicativo não está completamente acessível e nem todos os dados podem ser atualizados. Pedimos desculpas pelo inconveniente.\n\nPor favor, tenha paciência conosco enquanto trabalhamos com a mais alta prioridade para colocar o serviço novamente on-line. Obrigado por sua paciência e compreensão.',
  },
  understood: { de: 'Understood', en: 'Understood', pt: 'Compreendido' },
  // admin approval:
  approve_user: { de: 'Benutzerkonto freigeben', en: 'Approve user account', pt: 'Aprovar conta de usuário' },
  approve_user_info: {
    de: 'Ein Benutzer hat sich für ein neues Benutzerkonto registriert. Bitte prüfen Sie die nachstehenden Informationen und gewähren oder verweigern Sie den Zugang zu Loctite Pulse.',
    en: 'A user has registered for a new user account. Please review the information below and grant or deny access to Loctite Pulse.',
    pt: 'Um usuário se registrou para uma nova conta de usuário. Por favor, revise as informações abaixo e conceda ou negue acesso ao Loctite Pulse.'
  },
  grant_access: {
    de: 'Zugriff gewähren',
    en: 'Grant access',
    pt: 'Conceder acesso',
  },
  deny_access: {
    de: 'Zugriff verweigern',
    en: 'Deny access',
    pt: 'Negar acesso',
  },
  access_granted_success: {
    de: 'Dem Nutzer wurde Zugriff zu LOCTITE Pulse gewährt.',
    en: 'The user has been granted access to LOCTITE Pulse.',
    pt: 'O usuário recebeu acesso ao Loctite Pulse',
  },
  access_denied_success: {
    de: 'Dem Nutzer wurde Zugriff zu LOCTITE Pulse verweigert.',
    en: 'The user has been denied access to LOCTITE Pulse.',
    pt: 'O usuário teve seu acesso negado ao Loctite Pulse',
  },
  loading_approval_data_failed: {
    de: 'Beim Laden der Benutzerregistrierungsdaten ist ein Fehler aufgetreten. Die Freigabe wurde möglicherweise bereits bearbeitet oder ist abgelaufen. Bitte beachten Sie, dass Links zur Benutzerfreigabe nur 14 Tage lang gültig sind.',
    en: 'An error occurred while loading the user registration data. The approval may have already been processed or may have expired. Please note that user approval links are only valid for 14 days.',
    pt: 'Ocorreu um erro ao carregar os dados de registro do usuário. A aprovação pode já ter sido processada ou pode ter expirado. Observe que os links de aprovação de usuários são válidos apenas por 14 dias.'
  },
  registration_processing_failed: {
    de: 'Es ist ein Fehler beim konfigurieren des Zugangs aufgetreten.',
    en: 'An error occurred while configuring the access.',
    pt: 'Ocorreu um erro ao configurar o acesso',
  },
  // change pw:
  current_password: { de: 'Aktuelles Passwort', en: 'Current password', pt: 'Senha atual' },
  new_password_1: { de: 'Neues Passwort', en: 'New password', pt: 'Nova senha' },
  new_password_2: {
    de: 'Neues Passwort wiederholen',
    en: 'Repeat new password',
    pt: 'Repetir a nova senha',
  },
  change_password_submit: {
    de: 'Neues Passwort festlegen',
    en: 'Set new password',
    pt: 'Definir nova senha',
  },
  change_password_failed: {
    de: 'Passwortänderung fehlgeschlagen.',
    en: 'Password change failed.',
    pt: 'Campo de alteração de senha',
  },
  change_password_unauthorized: {
    de: 'Dieser Link ist abgelaufen. Bitte fordern Sie einen neuen Link an, um Ihr Passwort zu ändern.',
    en: 'This link has expired. Please request a new link to change your password.',
    pt: 'Este link expirou. Solicite um novo link para alterar sua senha.',
  },
  resend_mail: {
    de: 'E-Mail mit neuem Link anfordern.',
    en: 'Request email with new link.',
    pt: 'Solicitar e-mail com novo link',
  },
  passwords_must_be_equal: {
    de: 'Passwörter müssen identisch sein',
    en: 'Passwords must be identical',
    pt: 'As senhas devem ser idênticas',
  },
  pw_validation1: {
    de: 'Mindestens 12 Zeichen',
    en: 'At least 12 characters',
    pt: 'Pelo menos 12 characters',
  },
  pw_validation2: {
    de: 'Groß- und Kleinbuchstaben',
    en: 'Mix of uppercase and lowercase letters',
    pt: 'Deve conter letras maiúsculas e minúsculas',
  },
  pw_validation3: {
    de: 'Mindestens eine Ziffer',
    en: 'At least one digit',
    pt: 'Pelo menos um dígito',
  },
  pw_validation4: {
    de: 'Mindestens ein Sonderzeichen',
    en: 'At least one special character',
    pt: 'Pelo menos um caractere especial',
  },
  password_whitespace_validation: {
    de: 'Leerzeichen sind nicht erlaubt',
    en: 'Empty spaces not allowed',
    pt: 'Espaços vazios não são permitidos',
  },
  pw_validation5: {
    de: 'Passwörter stimmen nicht überein',
    en: 'Passwords do not match',
    pt: 'As senhas não correspondem',
  },
  pw_validation6: {
    de: 'Bestätigen Sie Ihr Passwort',
    en: 'Confirm your password',
    pt: 'Confirme sua senha',
  },
  email_validation1: {
    de: 'E-Mail Adresse ist erforderlich',
    en: 'Email address is required',
    pt: 'O endereço de e-mail é obrigatório',
  },
  email_validation2: {
    de: 'E-Mail-Adresse ist ungültig',
    en: 'Email address is not valid',
    pt: 'O endereço de e-mail não é válido',
  },
  email_validation3: {
    de: 'Diese E-Mail-Adresse wird bereits verwendet',
    en: 'Email address is already in use',
    pt: 'O endereço de e-mail já está em uso',
  },
  customer_required: {
    de: 'Auswahl ist erforderlich',
    en: 'Selection is required',
    pt: 'A seleção é necessária',
  },
  name_validation: {
    de: 'Vorname ist erforderlich',
    en: 'Name is required',
    pt: 'O nome é obrigatório',
  },
  surname_validation: {
    de: 'Nachname ist erforderlich',
    en: 'Surname is required',
    pt: 'O sobrenome é obrigatório',
  },
  length_validation: {
    de: 'Mindestens 3 Zeichen',
    en: 'At least 3 characters',
    pt: 'Pelo menos 3 caracteres',
  },
  // header:
  navigation: { de: 'Navigation', en: 'Navigation', pt: 'Navegação' },
  notifications: { de: 'Benachrichtigungen', en: 'Notifications', pt: 'Notificações' },
  settings: { de: 'Weitere Informationen', en: 'More information', pt: 'Mais informações' },
  // home page
  map: { de: 'Karte', en: 'Map', pt: 'Mapa' },
  map_panel: {
    de: 'Standort / Gebäude / Geräte',
    en: 'Plants / Buildings / Equipments',
    pt: 'Plantas/edifícios/equipamentos',
  },
  equipments: { de: 'Geräte', en: 'Equipments', pt: 'Equipamentos'},
  equip: { de: 'Geräte', en: 'Equip', pt: 'Equip' },
  sensors: { de: 'Sensoren', en: 'Sensors', pt: 'Sensores' },
  list: { de: 'Liste', en: 'List', pt: 'Lista' },
  satellite_view: { de: 'Satellitenansicht', en: 'Satellite View', pt: 'Visualização de satélite' },
  map_view: { de: 'Kartenansicht', en: 'Map View', pt: 'Visualização do mapa' },
  // filter:
  search: { de: 'Suche', en: 'Search', pt: 'Pesquisa' },
  filter: { de: 'Filter', en: 'Filter', pt: 'Filtro' },
  filters: { de: 'Filter', en: 'Filters', pt: 'Filtros' },
  find_equipment: { de: 'Finde Geräte', en: 'Find equipments', pt: 'Encontre equipamentos' },
  show_results: { de: 'Ergebnisse anzeigen', en: 'Show results', pt: 'Mostrar resultados' },
  clear_search: { de: 'Suche löschen', en: 'Clear search', pt: 'Limpar pesquisa' },
  clear_filter: { de: 'Filter löschen', en: 'Clear filters', pt: 'Limpar filtros' },
  status: { de: 'Status', en: 'Status', pt: 'Status' },
  all_status: { de: 'Jeder Status', en: 'All status', pt: 'Todos os status' },
  working: { de: 'Funktionsfähig', en: 'Working', pt: 'Trabalho' },
  warning: { de: 'Warnung', en: 'Warning', pt: 'Alerta' },
  critical: { de: 'Kritisch', en: 'Critical', pt: 'Crítico' },
  inactive: { de: 'Inaktiv', en: 'Inactive', pt: 'Inativo'},
  equipment_type: { de: 'Equipment-Typ', en: 'Equipment Type', pt: 'Tipo de equipamento' },
  flange: { de: 'Flansch', en: 'Flange', pt: 'Flange' },
  tank: { de: 'Tank', en: 'Tank', pt: 'Tanque' },
  pipe: { de: 'Rohrleitung', en: 'Pipe', pt: 'Tubo' },
  valve: { de: 'Ventil', en: 'Valve', pt: 'Válvula' },
  steamtrap: { de: 'Kondensatableiter', en: 'Steam Trap', pt: 'Purgador de Vapor' },
  rotatingequipment: { de: 'Rotierende Geräte', en: 'Rotating Equipment', pt: 'Equipamento rotativo' },
  rotatingEqu: { de: 'Rot. Geräte', en: 'Rotating Equ.', pt: 'Equipamento rotativo' },
  steamtrap_non_abbrev: { de: 'Kondensat-Ableiter', en: 'Steam Trap', pt: 'Purgador de vapor' },
  all_customers: { de: 'Alle Kunden ausgewählt', en: 'All customers selected', pt: 'Todo os clientes selecionados' },
  location: { de: 'Standort', en: 'Location', pt: 'Localização' },
  find_assets: { de: 'Finde Geräte', en: 'Find equipment', pt: 'Localizar equipamentos' },
  // plant list:
  equipment_found: { de: 'Gerät gefunden.', en: 'Equipment found', pt: 'Equipamento encontrado' },
  equipments_found: { de: 'Geräte gefunden.', en: 'Equipments found', pt: 'Equipamentos encontrados' },
  no_equipments_found: {
    de: 'Keine Geräte gefunden',
    en: 'No equipments found',
    pt: 'Nenhum equipamento encontrado',
  },
  no_equipments_found2: {
    de: 'Bitte Suche und Filter löschen.',
    en: 'Please clear your search and filters.',
    pt: 'Limpe sua pesquisa e seus filtros.',
  },
  // card component:
  critical_equipment: { de: 'kritisches Gerät', en: 'critical equipment', pt: 'Equipamento Crítico' },
  critical_equipments: { de: 'kritische Geräte', en: 'critical equipments', pt: 'Equipamentos críticos' },
  show_critical_equipments: {
    de: 'Kritische Geräte anzeigen',
    en: 'Show critical equipments',
    pt: 'Mostrar equipamentos críticos',
  },
  warning_equipment: { de: 'Warnung', en: 'warning', pt: 'Alerta' },
  warning_equipments: { de: 'Warnungen', en: 'warnings', pt: 'Alertas' },
  show_warning_equipments: {
    de: 'Geräte mit Warnungen anzeigen',
    en: 'Show warning equipments ',
    pt: 'Mostrar equipamentos em alerta',
  },
  in_active_equipment: { de: 'inaktives Gerät', en: 'in-active equipment', pt: 'Equipamento inativo' },
  in_active_equipments: { de: 'inaktive Geräte', en: 'in-active equipments', pt: 'Equipamentos inativos' },
  show_inactive_sensors: {
    de: 'In-active Sensoren anzeigen',
    en: 'Show in-active sensors',
    pt: 'Mostrar equipamentos inativos',
  },
  in_active_sensor: { de: 'inaktiver Sensor', en: 'in-active sensor', pt: 'Sensor inativo' },
  in_active_sensors: { de: 'inaktive Sensoren', en: 'in-active sensors', pt: 'Sensores inativos' },
  in_active: { de: 'Inaktiv', en: 'In-active', pt: 'Inativo' },
  other_equipment_working_fine: {
    de: 'anderes Gerät funktionsfähig',
    en: 'other equipment working fine',
    pt: 'outro equipamento funcionando bem',
  },
  other_equipments_working_fine: {
    de: 'andere Geräte funktionsfähig',
    en: 'other equipments working fine',
    pt: 'outros equipamentos funcionando bem',
  },
  all_equipments_working_fine: {
    de: 'Alle Geräte funktionsfähig',
    en: 'All equipments working fine',
    pt: 'Todos os equipamentos estão funcionando bem',
  },
  see_whole_plant: { de: 'Zur ganzen Anlage', en: 'See whole plant', pt: 'Ver planta inteira' },
  show_inactive_equipments: {
    de: 'De-installierte Sensordaten anzeigen',
    en: 'Show de-installed equipment',
    pt: 'Mostrar equipamentos desinstalados',
  },
  show_archive: {
    de: 'Archiv',
    en: 'Archive',
    pt: 'Arquivo',
  },
  // asset overview:
  back_to: { de: 'Zurück zu ', en: 'Back to ', pt: 'Voltar para' },
  back: {
    de: 'Zurück',
    en: 'Back',
    pt: 'Voltar',
  },
  sensor_status: { de: 'Sensorstatus', en: 'Sensor status', pt: 'Status do sensor' },
  health_status: { de: 'Gesundheitszustand', en: 'Health Status', pt: 'Estado de saúde' },
  remaining_useful_life: {
    de: 'Verbleibende Nutzungsdauer',
    en: 'Remaining useful life',
    pt: 'Vida útil remanescente' ,
  },
  analyst_dashboard: {
    de: 'Zum Analysten-Dashboard gehen',
    en: 'Go to analyst dashboard',
    pt: 'Ir para o painel de controle do analista' ,
  },
  connection_status: { de: 'Connection Status', en: 'Connection Status', pt: 'Status da conexão' },
  Find_sensor_ques: {
    de: 'Wie findet man den Sensor?',
    en: 'How to find the sensor?',
    pt: 'como encontrar o sensor' ,
  },
  Find_sensor_position: {
    de: 'Die Sensoren befinden sich entlang der Rohrabschnitte. Je niedriger die letzten drei Ziffern des Abschnittes sind, desto näher befindet sich der Sensor am Edge Device (ED).',
    en: 'The sensors are located along the pipe sections. The lower the last three digits of the section are, the closer the sensor is to the Edge Device (ED).',
    pt: 'Os sensores estão localizados ao longo das seções do tubo. Quanto mais baixos forem os três últimos dígitos da seção, mais próximo o sensor estará do EDGE DEVICE (ED).',
  },
  equipment_status: { de: 'Status des Equipments', en: 'Equipment Status', pt: 'Status do equipamento' },
  refresh_and_update: { de: 'Aktualisieren', en: 'Refresh and update', pt: 'Renova e Atualizar' },
  show_sensor_health: { de: 'EDGE DEVICE-STATUS', en: 'EDGE DEVICE HEALTH', pt:  'SAÚDE DO EDGE DEVICE' },
  edge_device_health: { de: 'Edge Device Status', en: 'Edge Device health', pt:  'Saúde do edge device' },
  please_check: { de: 'Bitte überprüfen', en: 'Please check', pt: 'Favor verificar' },
  working_fine: { de: 'Funktioniert einwandfrei', en: 'Working fine', pt: 'funcionando corretamente' },
  no_data: { de: 'Keine Daten', en: 'No data', pt: 'Não há dados' },
  sensor_checked: {
    de: 'Kein Sensorsignal gefunden',
    en: 'No sensor signal found',
    pt: 'Nenhum sinal de sensor encontrado' ,
  },
  network_issue: {
    de: 'wiederholen',
    en: ' Retry',
    pt: 'Repetir' ,
  },
  network_issue_msg: {
    de: 'Problem mit der Internetverbindung',
    en: 'Connection Issue Detected',
    pt: 'Problemas de conexão detectados',
  },
  network_issue_content: {
    de: 'Ihre Internetverbindung scheint instabil zu sein. Bitte überprüfen Sie Ihre Verbindung und versuchen Sie es erneut.',
    en: 'It looks like your internet connection is unavailable. Please check your connection and try again.',
    pt: 'Parece que sua conexão com a Internet não está disponível. Verifique sua conexão e tente novamente.' ,
  },
  network_issue_reconnect: {
    de: 'Wiederholen',
    en: 'Try to connect again',
    pt: 'Tente se conectar novamente' ,
  },
  ago1: { de: 'Zeit seit letzter Messung: ', en: '',pt: '' },
  ago2: { de: '', en: ' ago', pt: ' atrás' },
  never_checked: { de: 'Nie geprüft', en: 'Never checked', pt: 'Nunca verificado' },
  asset_not_found: {
    de: 'Gerät nicht gefunden :(',
    en: 'Equipment not found :(',
    pt: 'Equipamento não encontrado :(',
  },
  days: {
    de: 'Tage',
    en: 'Days',
    pt: 'Dias',
  },
  weeks: {
    de: 'Wochen',
    en: 'Weeks',
    pt: 'Semanas',
  },
  months: {
    de: 'Monate',
    en: 'Months',
    pt: 'Meses',
  },
  start: {
    de: 'Startdatum',
    en: 'Start date',
    pt: 'Data de início',
  },
  end: {
    de: 'Enddatum',
    en: 'End date',
    pt: 'Data final',
  },
  temperature_of_the_edge_device: {
    de: 'Temperatur des Edge Device',
    en: 'Temperature of the edge device',
    pt: 'Temperatura do edge device',
  },
  steam_trap_monitoring: {
    de: 'Kondensatableiter-Überwachung',
    en: 'Steam Trap Monitoring',
    pt: 'Monitoramento do purgador de vapor',
  },
  high_heat_resistance: {
    de: 'mit Sensor mit hoher Hitzebeständigkeit',
    en: 'with high heat resistance sensor',
    pt: 'Com sensor de alta resistência ao calor',
  },
  steam_trap_states: {
    de: 'Kondensatableiter Zustände %',
    en: 'Steam Trap States %',
    pt: 'Estados do purgador de vapor %',
  },
  steam_trap_health: {
    de: 'Kondensatableiter Zustand',
    en: 'Steam Trap Health',
    pt: 'Saúde do purgador de vapor',
  },
  steam_trap_working: {
    de: 'In Ordnung',
    en: 'Working',
    pt: 'Trabalho',
  },
  steam_trap_blow_through: {
    de: 'Durchstrahlen',
    en: 'Blow through',
    pt: 'Soprar',
  },
  steam_trap_blocked: {
    de: 'Blockiert',
    en: 'Blocked',
    pt: 'Bloqueado',
  },
  inactive_status: {
    de: 'Inaktiv',
    en: 'Inactive',
    pt: 'Inativo',
  },
  steam_loss: {
    de: 'Dampfverlust',
    en: 'Steam Loss',
    pt: 'Perda de vapor',
  },
  steam_loss_overview: {
    de: 'Übersicht Dampfverlust und Emissionen',
    en: 'Steam Loss and Emissions Overview',
    pt: 'Visão geral da perda de vapor',
  },
  steam_loss_overview_content: {
    de: 'Dies ist die Gesamtübersicht der Dampfverluste.',
    en: 'This is the overall steam loss.',
    pt: 'Esta é a visão geral da perda de vapor',
  },
  co2_content: {
    de: 'Dies ist die Gesamtübersicht der CO₂-Emissionen.',
    en: 'This is the overall CO₂ emission.',
  },
  steam_loss_prediction: {
    de: 'Vorhersage des Dampfverlustes',
    en: 'Steam Loss Prediction',
    pt: 'Previsão de perda de vapor',
  },
  steam_loss_prediction_content: {
    de: 'Höhe des Dampfverlustes bei einer Fehlfunktion des Kondensatableiters.',
    en: 'Amount of steam loss in case of malfunctioning steam trap.',
    pt: 'Quantidade de perda de vapor em caso de mau funcionamento do purgador',
  },
  co2_emission: {
    de: 'CO₂-Emis.',
    en: 'CO₂ Emis.',
  },
  in_the_next: {
    de: 'in den nächsten ',
    en: 'in the next ',
    pt: 'Na próxima ',
  },
  week_1: {
    de: 'Woche',
    en: '1 week',
    pt: '1 semana',
  },
  month_1: {
    de: 'Monat',
    en: '1 month',
    pt: '1 mês',
  },
  months_3: {
    de: 'drei Monaten',
    en: '3 months',
    pt: '3 meses',
  },
  inlet: {
    de: 'Einlass',
    en: 'Inlet',
    pt: 'Entrada',
  },
  outlet: {
    de: 'Auslass',
    en: 'Outlet',
    pt: 'Saída',
  },
  mean_inlet: {
    de: 'Einlass',
    en: 'Inlet',
    pt: 'Entrada',
  },
  mean_outlet: {
    de: 'Auslass',
    en: 'Outlet',
    pt: 'Saída',
  },
  min: {
    de: 'Min',
    en: 'Min',
    pt: 'Mínimo',
  },
  max: {
    de: 'Max',
    en: 'Max',
    pt: 'Máximo',
  },

  //rotating equipment
  rul_bold_content: {
    de: 'Die Restnutzungsdauer (RUL)',
    en: 'Remaining useful life (RUL)',
    pt: 'Vida útil restante (RUL)',
  },

  rul_content: {
    de: ' ist die geschätzte Zeit, die ein rotierendes Gerät effektiv arbeiten kann, bevor es ersetzt werden muss oder unzuverlässig wird. Sie ist entscheidend für die Wartungsplanung und die Minimierung von Ausfallzeiten.',
    en: ' is the estimated time a rotating equipment can operate effectively before needing replacement or becoming unreliable. Its vital for maintenance planning and minimizing downtime.',
    pt: 'É o tempo estimado que um equipamento rotativo pode operar com eficiência antes de precisar de substituição ou de se tornar não confiável. É vital para o planejamento da manutenção e para minimizar o tempo de inatividade.'
  },

  rul_image: {
    de: '% der Nutzungsdauer des Geräts verbraucht.',
    en: '% vitality of the equipment',
    pt: '% de vitalidade do equipamento',
  },

  // repair and replace:
  back_to_equipment_status: {
    de: 'Zum Status des Equipments',
    en: 'Back to equipment status',
    pt: 'Voltar ao status do equipamento',
  },
  repair_equipment: {
    de: 'Equipment reparieren',
    en: 'Repair equipment',
    pt: 'Equipamentos de reparo',
  },
  replace_sensor: {
    de: 'Sensor ersetzen',
    en: 'Replace sensor',
    pt: 'Substituir o sensor',
  },
  repair_overview: {
    de: 'Reparaturübersicht',
    en: 'Repair overview',
    pt: 'Visão geral do reparo',
  },
  step_by_step_instructions: {
    de: 'Schritt-für-Schritt-Anleitung',
    en: 'Step by step instructions',
    pt: 'Instruções passo a passo',
  },
  connect_new_sensor: {
    de: 'Neuen Sensor verbinden',
    en: 'Connect new sensor',
    pt: 'Conectar novo sensor',
  },
  // notifications:
  notification_found: {
    de: 'Benachrichtigung gefunden.',
    en: 'notification found.',
    pt: 'Notificação encontrada',
  },
  notifications_found: {
    de: 'Benachrichtigungen gefunden.',
    en: 'notifications found.',
    pt: 'Notificações encontradas',
  },
  no_notifications_found: {
    de: 'Keine Benachrichtigungen gefunden.',
    en: 'No notifications found.',
    pt: 'Não foram encontradas notificações',
  },
  problem_detected: {
    de: 'PROBLEM GEFUNDEN',
    en: 'PROBLEM DETECTED',
    pt: 'PROBLEMA DETECTADO',
  },
  equipment: {
    de: 'Equipment',
    en: 'Equipment',
    pt: 'Equipamentos',
  },
  equipment_short: {
    de: 'Equip.',
    en: 'Equip',
    pt: 'Equipar',
  },
  edge_device: {
    de: 'Edge Device',
    en: 'Edge Device',
    pt: 'Edge Device',
  },
  edge_device_short: {
    de: 'Hardware',
    en: 'Hardware',
    pt: 'Hardware',
  },
  repaired: {
    de: 'REPARIERT',
    en: 'REPAIRED',
    pt: 'REPARADO',
  },
  review_equipment_status: {
    de: 'Gerätzustand überprüfen',
    en: 'Review equipment status',
    pt: 'Revisar o status do equipamento',
  },
  sensor_instructions: {
    de: 'Sensor-Anweisungen',
    en: 'Sensor instructions',
    pt: 'Instruções do sensor',
  },
  add_documentation: {
    de: 'Dokumentation hinzufügen',
    en: 'Add documentation',
    pt: 'Adicionar documentação',
  },
  add_documentation_success: {
    de: 'Documentation erfolgreich hinzugefügt',
    en: 'Documentation successfully added',
    pt: 'Documentação adicionada com sucesso',
  },
  measured_value: {
    de: 'Gemessener Wert: ',
    en: 'Measured Data: ',
    pt: 'Dados medidos: ',
  },
  increased_value: {
    de: 'Wertanstieg: ',
    en: 'Increased Value: ',
    pt: 'Aumento do valor: ',
  },
  repair_and_replace: {
    de: 'Reparieren und ersetzen',
    en: 'Repair and replace',
    pt: 'Reparar e substituir',
  },
  detected_on: {
    de: 'Erkannt am',
    en: 'Detected on',
    pt: 'Detectado em',
  },
  // settings:
  language: { de: 'Sprache', en: 'Language', pt: 'Idioma' },
  change_password: { de: 'Passwort ändern', en: 'Change password', pt: 'Alterar senha' },
  measurement_system: {
    de: 'Einheitensystem & Format',
    en: 'Measurement system & format',
    pt: 'Sistema e formato de medição',
  },
  help_and_support: { de: 'Hilfe und Support', en: 'Help and Support', pt: 'Ajuda e suporte' },
  imprint: {
    de: 'Nutzungsbedingungen, Datenschutz und Impressum',
    en: 'Terms, privacy and imprint',
    pt: 'Termos, privacidade e impressão',
  },
  logout: { de: 'Abmelden', en: 'Logout', pt: 'Sair' },
  profile: { de: 'Benutzerprofil', en: 'User profile', pt: 'Perfil do usuário' },
  profile_photo: { de: 'Profilfoto', en: 'Profile photo', pt: 'Foto do perfil' },
  personal_information: { de: 'Persönliche Daten', en: 'Personal information', pt: 'Informações pessoais' },
  save_changes: { de: 'Änderungen speichern', en: 'Save changes', pt: 'Salvar alterações' },
  apply_changes: { de: 'Änderungen anwenden', en: 'Apply changes', pt: 'Aplicar alterações' },
  appearance: { de: 'Aussehen', en: 'Appearance', pt: 'Aparência' },
  save_information: { de: 'Informationen speichern', en: 'Save information', pt: 'Salvar informações' },
  changes_successful: {
    de: 'Die Änderungen wurden erfolgreich umgesetzt.',
    en: 'The changes were implemented successfully.',
    pt: 'As mudanças foram implementadas com sucesso.',
  },
  changes_problem: {
    de: 'Es ist ein Problem aufgetreten und die Änderungen konnten nicht umgesetzt werden.',
    en: 'There has been a problem and the changes could not be implemented.',
    pt: 'Houve um problema e as alterações não puderam ser implementadas',
  },
  system_of_measurement: { de: 'Maßsystem', en: 'System of measurement', pt: 'Sistema de medição' },
  date_format: { de: 'Datumsformat', en: 'Date format', pt: 'Formato da data' },
  allow_push_notification: {
    de: 'Push-Benachrichtigung erlauben',
    en: 'Allow push notification',
    pt: 'Permitir notificação por push',
  },
  allow_push_notifications: {
    de: 'Alle Push-Benachrichtigungen zulassen',
    en: 'Allow all push notifications',
    pt: 'Permitir notificações push',
  },
  allow_email_notifications: {
    de: 'Alle Emails zulassen',
    en: 'Allow all emails',
    pt: 'Permitir todos os e-mails',
  },
  notificationsflange: {
    de: 'Benachrichtigungen Flansch',
    en: 'Flange Notifications',
    pt: 'Notificações de flange',
  },
  notificationspipe: {
    de: 'Benachrichtigungen Rohrleitung',
    en: 'Pipe Notifications',
    pt: 'Notificações de tubulação',
  },
  notificationssteamtrap: {
    de: 'Benachrichtigungen Kondensatableiter',
    en: 'Steam Trap Notifications',
    pt: 'Notificações de purgadores de vapor',
  },
  push_notification: { de: 'Push-Benachrichtigung', en: 'Push notification', pt: 'Notificações push' },
  push_notification_abbrev: { de: 'Push-Benachr.', en: 'Push notif.', pt: 'Notificações push' },
  sms: { de: 'SMS-Benachrichtigung', en: 'SMS notification', pt: 'Notificação por SMS' },
  sms_abbrev: { de: 'SMS-Benachr.', en: 'SMS notif.', pt: 'Notificação por SMS' },
  warning_notifications: { de: 'Warnungen', en: 'Warnings', pt: 'Avisos' },
  in_active_notification_msg: {
    de: 'Letzte Übertragung am',
    en: 'Last transmission detected on',
    pt: 'Última transmissão detectada em',
  },
  allow_email: { de: 'E-Mails erlauben', en: 'Allow emails', pt: 'Permitir e-mails' },
  push_notification_modal_text: {
    de: 'Möchten Sie Benachrichtigungen von dieser Seite erhalten?',
    en: 'Would you like to receive notifications from this site?',
    pt: 'Você gostaria de receber notificações deste site?',
  },
  always: {
    de: 'Jedes Mal',
    en: 'Always',
    pt: 'Sempre',
  },
  never: {
    de: 'Nie',
    en: 'Never',
    pt: 'Nunca',
  },
  push_permission_granted: {
    de: 'Berechtigung für Push Benachrichtigungen erteilt.',
    en: 'Permission for push notifications granted.',
    pt: 'Permissão para notificações push concedida',
  },
  push_permission_denied: {
    de: 'Berechtigung für Push Benachrichtigungen verweigert.',
    en: 'Permission for push notifications denied.',
    pt: 'Permissão para notificações push negada',
  },
  instructions: {
    de: 'Anweisungen',
    en: 'Instructions',
    pt: 'Instruções',
  },
  okay: {
    de: 'Okay',
    en: 'Okay',
    pt: 'Certo',
  },
  add_to_home_screen_instructions_ios: {
    de: 'Um die App zum Startbildschirm hinzuzufügen, tippen Sie auf das Teilen-Symbol und wählen Sie "Zum Startbildschirm".',
    en: 'To add the app to the Home Screen tap the share icon and select "Add to Home Screen".',
    pt: 'Para adicionar o aplicativo à tela inicial, toque no ícone de compartilhamento e selecione “Add to Home Screen” (Adicionar à tela inicial).',
  },
  error_triggering_notification_prompt: {
    de: 'Benachrichtigungsberechtigungsaufforderung konnte nicht ausgelöst werden: ',
    en: 'Could not trigger notification permission prompt: ',
    pt: 'Não foi possível acionar o prompt de permissão de notificação: ',
  },

  error_displaying_notification_prompt: {
    de: 'Benachrichtigungsberechtigungsaufforderung konnte nicht angezeigt werden: ',
    en: 'Could not display notification permission prompt: ',
    pt: 'Não foi possível exibir o prompt de permissão de notificação: ',
  },

  error_solving_notification_prompt: {
    de: 'Benachrichtigungsberechtigungsaufforderung konnte nicht gelöst werden: ',
    en: 'Could not solve notification permission prompt: ',
    pt: 'Não foi possível resolver o prompt de permissão de notificação: ',
  },
  notifications_not_supported_title: {
    de: 'Benachrichtigungen nicht unterstützt',
    en: 'Notifications not supported',
    pt: 'Não há suporte para notificações',
  },
  safari_not_supporting_notifications: {
    de: 'Ihr Browser unterstützt keine Push-Benachrichtigungen. Wenn Sie ein Apple-Gerät verwenden, stellen Sie bitte sicher, dass Ihr Betriebssystem auf dem neuesten Stand ist und dass die Website zu Ihrem Startbildschirm hinzugefügt wurde.',
    en: 'Your browser does not support push notifications. If you are on an Apple Device please make sure that your operating system is up to date and that the website is added to your home screen.',
    pt: 'Seu navegador não é compatível com notificações por push. Se estiver usando um dispositivo Apple, verifique se o seu sistema operacional está atualizado e se o site foi adicionado à sua tela inicial.'
  },
  browser_not_supporting_notifications: {
    de: 'Ihr Browser unterstützt keine Push-Benachrichtigungen.',
    en: 'Your browser does not support push notifications.',
    pt: 'Seu navegador não é compatível com notificações por push.',
  },
  email_will_be_sent_to: {
    de: 'E-Mail wird gesendet an ',
    en: 'Email will be sent to ',
    pt: 'O e-mail será enviado para',
  },
  about_loctite: { de: 'Über Loctite', en: 'About Loctite', pt: 'Sobre loctite' },
  faq: { de: 'FAQ', en: 'FAQ', pt: 'Perguntas frequentes' },
  your_contact: { de: 'Ihr Kontakt:', en: 'Your contact:', pt: 'Seu contato:'},
  q_change_appearance: {
    de: 'Ist es möglich, das Aussehen der App zu ändern?',
    en: 'Is it possible to change the appareance of the app?',
    pt: 'É possível alterar a aparência do aplicativo?',
  },
  q_document_problem: {
    de: 'Wie kann ich ein Problem dokumentieren?',
    en: 'How can I document a problem?',
    pt: 'Como posso documentar um problema?',
  },
  q_sensor_health: {
    de: 'Was ist Sensorstatus?',
    en: 'What is sensor health?',
    pt: 'O que é a integridade do sensor?',
  },
  alerts: {
    de: 'Alarme',
    en: 'Alerts',
    pt: 'Alertas',
  },
  warnings: {
    de: 'Warnungen',
    en: 'Warnings',
    pt: 'Avisos',
  },
  // Technical Documents
  technical_documents: {
    de: 'Technische Dokumente',
    en: 'Technical documents',
    pt: 'Documentos técnicos',
  },
  view_technical_documents: {
    de: 'Technische Dokumente ansehen',
    en: 'View technical documents',
    pt: 'Ver documentos técnicos',
  },
  technical_documents_smart_flange: {
    de: 'Technische Dokumente für Smart Flange',
    en: 'Technical documents for Smart Flange',
    pt: 'Documentos técnicos para Flanges',
  },
  technical_documents_steamtrap: {
    de: 'Technische Dokumente für Kondensat-ableiter',
    en: 'Technical documents for Steam Trap',
    pt: 'Documentos técnicos para Purgador de vapor',
  },
  technical_documents_rotatingequipment: {
    de: 'Technische Dokumente für rotierende Geräte',
    en: 'Technical documents for Rotating Equipment',
    pt: 'Documentos técnicos para Equipamentos rotativos',
  },
  doctype_label_product: {
    de: 'Produktdatenblatt (Englisch)',
    en: 'Product Description Sheet',
    pt: 'Folha de descrição do produto',
  },
  select_language: {
    de: 'Sprache auswählen',
    en: 'Select language',
    pt: 'Selecione o idioma',
  },
  doctype_label_manual: {
    de: 'Bedienungsanleitung (Englisch)',
    en: 'Manual',
    pt: 'Manual',
  },
  show_document: {
    de: 'Dokument anzeigen',
    en: 'Show document',
    pt: 'Exibir documento',
  },
  //cookie consent

  cookie_consent: {
    de: 'Cookie Zustimmung',
    en: 'Cookie Consent',
    pt: 'Consentimento de cookies',
  },
  cookies_settings: {
    de: 'Cookie Einstellungen',
    en: 'Cookies Settings',
    pt: 'Configurações de cookies',
  },
  cookie_consent_first_paragraph: {
    de: 'Sofern Sie eingewilligt haben, benutzt diese Website Adobe Analytics, einen Webanalysedienst der Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business Campus, Saggart, Dublin 24, Irland) ("Adobe"). Zu diesem Zweck wird ein Cookie auf Ihrem Endgerät installiert. Dieses Cookie sammelt Daten wie Browser- und Geräteinformationen, Ihre IP-Adresse, besuchte Webseiten sowie Datum und Uhrzeit der Serveranfrage, um Ihr Nutzerverhalten unserer Webseite auszuwerten, um Berichte über die Webseitenaktivität für Webseitenbetreiber zu erstellen und um weitere mit der Webseitennutzung und der Internetnutzung verbundene Dienstleistungen gegenüber dem Webseitenbetreiber zu erbringen.',
    en: 'Provided you have consented, this website uses Adobe Analytics, a web analytics service provided by Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business Campus, Saggart, Dublin 24, Ireland) (“Adobe”). For this purpose, a cookie is installed on your device. This cookie will collect data such as browser and device information, your IP address, visited websites, and date and time of server request for the purpose of evaluating your use of the website, compiling reports on website activity for website operators and providing other services relating to website activity and internet usage to the website provider.',
    pt: 'Se você tiver dado seu consentimento, este site usa o Adobe Analytics, um serviço de análise da Web fornecido pela Adobe Systems Software Ireland Limited (4-6 Riverwalk, City West Business Campus, Saggart, Dublin 24, Irlanda) (“Adobe”). Um cookie é instalado em seu dispositivo final para essa finalidade. Esse cookie coleta dados como informações do navegador e do dispositivo, seu endereço IP, sites visitados e a data e hora da solicitação do servidor para avaliar o comportamento do usuário em nosso site, para compilar relatórios sobre a atividade do site para os operadores do site e para fornecer outros serviços relacionados à atividade do site e ao uso da Internet para o operador do site.',
  },
  cookie_consent_second_paragraph: {
    de: 'Adobe (als für die Verarbeitung Verantwortlicher) kann Ihre Daten für eigene Zwecke wie die Profilerstellung und die Verknüpfung Ihrer Daten mit anderen über Sie erhobenen Daten (z. B. über Ihr Adobe-Konto) nutzen.',
    en: 'Adobe (as a controller) may use your data for any own purposes as for profiling and linking your data to other data collected about you (e.g. through your Adobe Account).',
    pt: 'A Adobe (como controladora de dados) poderá usar seus dados para seus próprios fins, tais como a criação de perfis e a vinculação de seus dados a outros dados coletados sobre você (por exemplo, por meio de sua conta da Adobe).',
  },
  cookie_consent_third_paragraph: {
    de: 'Die durch das Cookie erzeugten Informationen über Ihre Webseitennutzung (einschließlich Ihrer IP-Adresse) werden an einen Server von Adobe in das Vereinigte Königreich übertragen und dort verarbeitet. Für diese Datenübermittlung in ein Drittland wird gemäß Art. 46 DSGVO ein angemessenes Datenschutzniveau durch Standardvertragsklauseln sichergestellt, darüber hinaus existiert für das Vereinigte Königreich ein Angemessenheitsbeschluss der Europäischen Kommission (Art. 45 DSGVO).',
    en: 'The information generated by the cookie about your use of the website (including your IP address) will be transmitted to and processed by Adobe on servers in the United Kingdom. For this third country data transfer, an adequate level of data protection is ensured by standard contractual clauses, Art. 46 GDPR, pending an adequacy decision by the European Commission (Art. 45 GDPR).',
    pt: 'As informações geradas pelo cookie sobre seu uso do site (inclusive seu endereço IP) serão transmitidas e processadas pela Adobe em servidores no Reino Unido. Para essa transferência de dados para um terceiro país, um nível adequado de proteção de dados é garantido por cláusulas contratuais padrão de acordo com o Art. 46 do GDPR; além disso, existe uma decisão de adequação da Comissão Europeia para o Reino Unido (Art. 45 do GDPR).',
  },
  cookie_consent_fourth_paragraph: {
    de: 'Wir haben die IP-Anonymisierung aktiviert, sodass das letzte Oktett (der letzte Teil) der IP-Adresse sofort unkenntlich gemacht wird, wenn die IP-Adresse von Adobe erfasst wird. Diese Anonymisierung wird vor jeder Verarbeitung der IP-Adresse durchgeführt. Dadurch ist lediglich nur ein ungefährer Standort zu statistischen Analysezwecken verfügbar. Nach der Geolokalisierung wird die IP-Adresse durch die generische IP-Adresse ::X.X.X.X. ersetzt.',
    en: 'We have activated the IP anonymization, which means that the last octet (the last portion) of the IP address is immediately hidden when the IP address is collected by Adobe. This anonymization is performed prior to any processing of the IP address. Only an approximate location will be available for the purpose of statistical analysis. After the geo-lookup, everywhere in Analytics the IP addresses are obfuscated – replaced with :: X.X.X.X.',
    pt: 'Ativamos a anonimização de IP para que o último octeto (a última parte) do endereço IP seja imediatamente irreconhecível quando o endereço IP for registrado pela Adobe. Essa anonimização é realizada antes de qualquer processamento do endereço IP. Como resultado, apenas uma localização aproximada está disponível para fins de análise estatística. Após a geolocalização, o endereço IP é substituído pelo endereço IP genérico ::X.X.X.X.',
  },
  cookie_consent_fifth_paragraph: {
    de: 'Indem Sie Cookies auf der Henkel-Webseite zulassen, erklären Sie sich mit der Nutzung der oben genannten Daten und der zuvor beschriebenen Verarbeitung durch Adobe einverstanden.\n Weitere Informationen zum Datenschutz bei Adobe-Diensten finden Sie hier: "https://www.adobe.com/privacy/p"',
    en: 'By allowing cookies on the Henkel-website you agree to the use of the above-mentioned data and the previously described processing by Adobe.\n You find more information about privacy in Adobe services here: "https://www.adobe.com/privacy/policy.html"',
    pt: 'Ao permitir cookies no site da Henkel, você concorda com o uso dos dados acima mencionados e com o processamento descrito acima pela Adobe. Mais informações sobre a proteção de dados para os serviços da Adobe podem ser encontradas aqui: “https://www.adobe.com/privacy/p”',
  },
  cookie_consent_sixth_paragraph: {
    de: 'Sie können Ihre Einwilligung jederzeit mit Wirkung für die Zukunft widerrufen, indem Sie die Cookies auf unserer Webseite unter „Cookies“ bei „Cookie-Einstellungen“ deaktivieren. Wenn Sie mit der Verwendung von Cookies generell einverstanden sind, Sie aber die Verwendung von Adobe Analytics-Cookies verhindern wollen, können Sie diesem Link folgen (https://www.adobe.com/privacy/opt-out.html), um die Erfassung und Verwendung von Daten (Cookies und IP-Adresse) durch Adobe Analytics zu deaktivieren.',
    en: 'You can withdraw your consent at any time with effect for the future by disabling cookies on our website under "Cookies" at "Cookie settings". If you agree to the use of cookies in general, but however, you are uncomfortable with the use of Adobe Analytics cookies, you can follow this link (https://www.adobe.com/privacy/opt-out.html) and deactivate the Adobe Analytics collection and use of data (cookies and IP address).”',
    pt: 'Você pode revogar seu consentimento a qualquer momento, com efeito para o futuro, desativando os cookies em nosso site em “Cookies” em “Configurações de cookies”. Se, de modo geral, você concordar com o uso de cookies, mas desejar impedir o uso de cookies do Adobe Analytics, poderá seguir este link (https://www.adobe.com/privacy/opt-out.html) para desativar a coleta e o uso de dados (cookies e endereço IP) pelo Adobe Analytics.',
  },
  cookie_consent_subtitle: {
    de: 'Widerruf der Datenerhebung:',
    en: 'Objection to data collection:',
    pt: 'Revogação da coleta de dados:',
  },
  agree_to: {
    de: 'Ich bin mit den ',
    en: 'I agree to the ',
    pt: 'Eu concordo com os ',
  },
  service_conditions: {
    de: 'Servicebedingungen',
    en: 'Service Conditions',
    pt: 'Condições de serviço',
  },
  the: {
    de: ' und den ',
    en: ', the ',
    pt: 'o',
  },
  app_terms_use: {
    de: 'Nutzungsbedingungen der App',
    en: 'App Terms of Use',
    pt: 'Termos de uso do aplicativo',
  },
  and_have_read: {
    de: ' einverstanden und habe die ',
    en: ' and I have read the ',
    pt: 'e eu li o',
  },

  // Terms of service text
  terms_of_service: { de: 'Nutzungsbedingungen', en: 'Terms of use',pt: 'Termos de uso' },
  // Privacy policy text
  privacy_policy: { de: 'Datenschutzbestimmungen', en: 'Privacy policy', pt: 'Política de privacidade' },
  agree_to_general: {
    de: 'Ich erkläre mich mit den Allgemeinen ',
    en: 'I agree to the General ',
    pt: 'Eu concordo com o General',
  },
  terms_and_conditon: {
    de: 'Nutzungsbedingungen einverstanden',
    en: 'Terms and Condition of Use',
    pt: 'Termos e condições de uso',
  },
  have_read: { de: 'Ich habe die Erklärung zum ', en: 'I have read the ', pt: 'Eu li o' },
  data_protection_decalaration: {
    de: 'Datenschutzerklärung gelesen',
    en: 'Data Protection Declaration',
    pt: 'Declaração de proteção de dados',
  },
  cookies: { de: 'Cookies', en: 'Cookies', pt: 'Cookies' },
  CAPrivacyRights: { de: 'CA Privacy Rights', en: 'CA Privacy Rights', pt: 'Direitos de pagamento da CA' },
  DSAR: {
    de: 'Do Not Sell or Share My Personal Information',
    en: 'Do Not Sell or Share My Personal Information',
    pt: 'Não vender ou compartilhar minhas informações pessoais',
  },
  //scroll to top component
  back_to_top: { de: 'Zurück zum Seitenanfang', en: 'Back to top', pt: 'Voltar ao topo' },

  // equipment overview:
  additional_information: {
    de: 'Zusätzliche Informationen',
    en: 'Additional Information',
    pt: 'Informações adicionais',
  },
  information_about_equipment: {
    de: 'Informationen zum Gerät',
    en: 'Information about equipment',
    pt: 'Informações sobre equipamentos',
  },
  sensor_installation_data: {
    de: 'Sensor-Installationsdaten',
    en: 'Sensor installation data',
    pt: 'Dados de instalação do sensor',
  },
  sensor_name: {
    de: 'SENSOR NAME',
    en: 'SENSOR NAME',
    pt: 'NOME DO SENSOR',
  },
  sensor_number: {
    de: 'SENSORNUMMER',
    en: 'SENSOR NUMBER',
    pt: 'NÚMERO DO SENSOR',
  },
  edge_device_number: {
    de: 'EDGEDEVICE-NUMMER',
    en: 'EDGE DEVICE NUMBER',
    pt: 'NÚMERO DO EDGE DEVICE',
  },
  inlet_number: {
    de: 'NUMBER AT THE SENSOR INPUT',
    en: 'SENSOR INLET NUMBER',
    pt: 'NÚMERO DA ENTRADA DO SENSOR',
  },
  outlet_number: {
    de: 'NUMBER AT THE SENSOR OUTPUT',
    en: 'SENSOR OUTLET NUMBER',
    pt: 'NÚMERO DA SAÍDA DO SENSOR',
  },
  dau_code: {
    de: 'DAU-CODE',
    en: 'DAU CODE',
    pt: 'CÓDIGO DAU',
  },
  gateway_code: {
    de: 'GATEWAY-CODE',
    en: 'GATEWAY CODE',
    pt: 'CÓDIGO GATEWAY',
  },
  junction_box_slot: {
    de: 'JUNCTION BOX',
    en: 'JUNCTION BOX',
    pt: 'CAIXA DE JUNÇÃO',
  },
  additional_documents: {
    de: 'Zusätzliche Dokumente',
    en: 'Additional documents',
    pt: 'Documentos adicionais',
  },
  show: { de: 'Anzeigen', en: 'Show', pt: 'Mostrar' },
  needs_to_be_repaired: {
    de: 'Muss repariert werden',
    en: 'Needs to be repaired',
    pt: 'Necessita de reparo',
  },
  problem_fixed: { de: 'Problem gelöst', en: 'Problem fixed', pt: 'Problema corrigido' },
  false_alert: { de: 'Falscher Alarm', en: 'False alert', pt: 'Alerta falso' },
  others: { de: 'Sonstiges', en: 'Others', pt: 'Outros' },
  note: { de: 'Notiz', en: 'Note', pt: 'Observação' },
  photo: { de: 'Foto', en: 'Photo', pt: 'Foto' },
  photos: { de: 'Fotos', en: 'Photos', pt: 'Fotos' },
  files: { de: 'Dateien', en: 'Files', pt: 'Arquivos' },
  sensor_health: { de: 'Sensorstatus', en: 'Sensor health', pt: 'Saúde do sensor' },
  //banner-maintenance
  banner_heading_maintenance: {
    de: 'Wir nehmen einige Verbesserungen vor.',
    en: 'We´re making some improvements.',
    pt: 'Estamos fazendo alguns aprimoramentos',
  },
  banner_maintenance_text1: {
    de: 'Vielen Dank für Ihre Geduld. Unsere App wird derzeit einer planmäßigen Wartung unterzogen, um Ihre Erfahrung zu verbessern. Ihre Anlagen werden weiterhin überwacht und wir werden Sie bei Bedarf direkt kontaktieren.',
    en: 'Thank you for your patience. Our app is currently undergoing scheduled maintenance to improve your experience. Your assets are still being monitored and we´ll contact you directly if needed.',
    pt:'Obrigado por sua paciência. Nosso aplicativo está passando por uma manutenção programada para melhorar sua experiência. Seus equipamentos ainda estão sendo monitorados e entraremos em contato diretamente com você, se necessário.'
  },
  banner_maintenance_text2: {
    de: 'Bitte haben Sie Geduld mit uns, während wir daran arbeiten, den Dienst wieder zum Laufen zu bringen.',
    en: 'Please bear with us as we work to bring the service back up and running.',
    pt: 'Por favor, tenha paciência , estamos trabalhamos para que o serviço volte a funcionar.'
  },
  // sensor health:
  of_the_edge_device: { de: 'des Edge Device', en: 'of the edge device', pt: 'do edge device' },
  problem_detected_title: {
    de: 'Problem festgestellt',
    en: 'Problem detected',
    pt: 'Problema detectado',
  },
  problem_detected_text: {
    de: 'Es wurde ein Problem mit dem Edge-Gerät festgestellt. Bitte ersetzen Sie es bei Bedarf und gleichen Sie alle an dieses Edge-Device angeschlossenen Sensoren ab.',
    en: 'A problem has been detected with the Edge device. Please replace it if necessary and match all sensors connected to this Edge device.',
    pt: 'Foi detectado um problema com o dispositivo do Edge. Substitua-o, se necessário, e combine todos os sensores conectados a esse dispositivo Edge.'
  },
  replace_edge_device_button: {
    de: 'Edge-Device ersetzen',
    en: 'Replace edge device',
    pt: 'Substituir o edge device',
  },
  low_battery_text: {
    de: 'Schwache Batterie, bitte ersetzen Sie das Edge-Device.',
    en: 'Low battery, please replace the edge device.',
    pt: 'Bateria fraca, substitua o edge device.',
  },
  low_signal_text: {
    de: 'Niedrige Signalqualität, bitte überprüfen Sie die Konnektivität des Edge-Device und reparieren oder ersetzen Sie es, falls erforderlich.',
    en: 'Low signal quality, please check the connectivity of the edge device and repair or replace if necessary.',
    pt: 'Qualidade de sinal baixa, verifique a conectividade do edge device  e repare ou substitua, se necessário.',
  },
  tilt_text: {
    de: 'Gerät gekippt, bitte überprüfen Sie das Edge-Device und stellen Sie es in die richtige Position.',
    en: 'Device tilted, please check the edge device and put it in the correct position.',
    pt: 'Dispositivo inclinado, verifique o edge device e coloque-o na posição correta.',
  },
  tilt_text_warning: {
    de: 'Es scheint, dass das Edge Device verschoben wurde und nicht mehr in aufrechter Position steht. Bitte überprüfen Sie dies bei einem Ihrer nächsten Kontrollgänge.',
    en: 'It seems that the edge device has been moved and is not placed in an upright position any more. Please check in one of your next inspection rounds.',
    pt: 'Parece que o edge device foi movido e não está mais na posição vertical. Verifique em uma de suas próximas rodadas de inspeção.'
  },
  tilt_true_message: {
    de: 'Edge-Device gekippt',
    en: 'Edge device tilted',
    pt: 'Edge device inclinado',
  },
  tilt_false_message: {
    de: 'Edge-Device in korrekter Position',
    en: 'Device in correct position',
    pt: 'Dispositivo na posição correta',
  },
  // telemetry:
  sensor_data: { de: 'Sensordaten', en: 'Sensor Data', pt: 'Dados do sensor' },
  history: { de: 'Historie', en: 'History', pt:'Histórico' },
  show_all_entries: { de: 'Alle Einträge anzeigen', en: 'Show all entries', pt: 'Mostrar todas as entradas' },
  show_details: { de: 'Details anzeigen', en: 'Show details', pt: 'Mostrar detalhes' },
  hide_details: { de: 'Details ausblenden', en: 'Hide details', pt: 'Ocultar detalhes' },
  // documentation wizard:
  pick_something_that_applies: {
    de: 'Wählen Sie etwas aus, das zutrifft',
    en: 'Pick something that applies',
    pt: 'Escolha algo que se aplique',
  },
  please_add_more_information: {
    de: 'Bitte fügen Sie weitere Informationen hinzu',
    en: 'Please add more information',
    pt: 'Por favor, adicione mais informações',
  },
  add_note: { de: 'Notiz hinzufügen', en: 'Add note', pt: 'Adicionar nota' },
  save_note: { de: 'Notiz speichern', en: 'Save note', pt: 'Salvar nota' },
  take_or_upload_a_photo: {
    de: 'Foto aufnehmen oder hochladen (optional)',
    en: 'Take or upload a photo (optional)',
    pt: 'Tirar ou carregar fotos(opcional)',
  },
  take_a_photo: {
    de: 'Foto aufnehmen (optional)',
    en: 'Take a photo (optional)',
    pt: 'Tire uma foto (opcional)',
  },
  upload_a_photo: {
    de: 'Foto hochladen (optional)',
    en: 'Upload a photo (optional)',
    pt: 'Faça upload de uma foto (opcional)',
  },
  add_file: { de: 'Datei hinzufügen (optional)', en: 'Add a file (optional)', pt: 'Adicionar um arquivo (opcional)' },
  save_documentation: {
    de: 'Dokumentation speichern',
    en: 'Save documentation',
    pt: 'Salvar documentação'
  },
  max_size: { de: 'max. Größe: 20MB', en: 'Max-size: 20MB', pt: 'Tamanho máximo de 20 MB' },
  upload_file: { de: 'Datei hochladen', en: 'Upload a file', pt: 'Fazer upload de outro arquivo' },
  upload_another_file: {
    de: 'Eine weitere Datei hochladen',
    en: 'Upload another file',
    pt: 'Fazer upload de outro arquivo',
  },
  connection_error: {
    de: 'Etwas ist schief gelaufen',
    en: 'Something went wrong.',
    pt: 'Algo deu errado',
  },
  // new:
  name: { de: 'Vorname', en: 'Name', pt: 'Nome' },
  surname: { de: 'Nachname', en: 'Surname', pt: 'Sobrenome' },
  designation: { de: 'Berufsbezeichnung', en: 'Job Title', pt: 'Título do cargo' },
  phonenr: { de: 'Handynummer', en: 'Cell phone number', pt: 'Número de telefone celular' },
  optional: { de: 'optional', en: 'optional', pt: 'Opcional' },
  error_loading_data: {
    de: 'Fehler beim Laden der Daten',
    en: 'Error loading data',
    pt: 'Erro ao carregar dados',
  },
  // view building plan
  view_building_plan: { de: 'Gebäudeplan anschauen', en: 'View building plan', pt: 'Ver planta do edifício' },
};
