import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { GroupedAdditionalInfo } from '../../model/additionalinfo';
import { AssetDetail } from '../../model/asset';
import { Sensor } from '../../model/sensor';
import { I18nService } from '../../services/i18n.service';
import { UiService } from '../../services/ui.service';

@Component({
  selector: 'app-additional-info',
  templateUrl: './additional-info.component.html',
  styleUrls: ['./additional-info.component.scss'],
})
export class AdditionalInfoComponent implements OnInit {
  @Input() asset: AssetDetail = null;
  @Input() Sensor: Sensor = null;
  @Input() groupedInfoValues: GroupedAdditionalInfo[];

  public overlayImage = null;
  public addInfo: boolean[] = [false, false, false, false];

  get InstallationData(): {
    header: string;
    items: {
      text: string;
      value: number;
      valueType: number;
      category: string;
      icon: string;
    }[];
  }[] {
    function formatSerialNumber(input: string): string {
      const cached_input = '' + input;
      try {
        input = input.toUpperCase();
        const strArray = Array.from(Array(input.length).keys()).map(
          (i: number) => input.charAt(i)
        );
        return strArray
          .map((char: string) => char)
          .reduce((a: string, b: string) => a + b);
      } catch (e) {
        return cached_input;
      }
    }

    function formatMAC(input: string): string {
      const cached_input = '' + input;
      try {
        if (input.length !== 12) return input;
        input = input.toUpperCase();
        const strArray = Array.from(Array(input.length).keys()).map(
          (i: number) => input.charAt(i)
        );
        return strArray
          .map((char: string, index: number, array: []) =>
            index % 2 === 0 || index >= array.length - 1 ? char : char + ':'
          )
          .reduce((a: string, b: string) => a + b);
      } catch {
        return cached_input;
      }
    }
    if (
      !!this.Sensor &&
      this.asset?.equipmentType !== 'Steam Trap' && // show for leakage sensor only
      (!!this.Sensor.sensorDigitID ||
        !!this.Sensor.edgeDigitID ||
        !!this.Sensor.junctionBoxSlot)
    ) {
      const output = [];
      output.push({
        header: this.i18n.string('sensor_name'),
        items: [
          {
            text: this.Sensor.sensorName,
            value: null,
            valueType: null,
            category: this.i18n.string('sensor_name'),
            icon: null,
          },
        ],
      });
      if (!!this.Sensor.sensorDigitID)
        output.push({
          header: this.i18n.string('sensor_number'),
          items: [
            {
              text: this.Sensor.sensorDigitID,
              value: null,
              valueType: null,
              category: this.i18n.string('sensor_number'),
              icon: null,
            },
          ],
        });
      if (!!this.Sensor.edgeDigitID)
        output.push({
          header: this.i18n.string('edge_device_number'),
          items: [
            {
              text: this.Sensor.edgeDigitID,
              value: null,
              valueType: null,
              category: this.i18n.string('edge_device_number'),
              icon: null,
            },
          ],
        });
      if (!!this.Sensor.junctionBoxSlot)
        output.push({
          header: this.i18n.string('junction_box_slot'),
          items: [
            {
              text: this.Sensor.markerText,
              value: null,
              valueType: null,
              category: this.i18n.string('junction_box_slot'),
              icon: null,
            },
          ],
        });
      return output;
    } else if (
      !!this.Sensor &&
      this.asset?.equipmentType === 'Steam Trap' && // show for steamtrap sensor only
      (!!this.Sensor.inletNumber ||
        !!this.Sensor.outletNumber ||
        !!this.Sensor.DAU ||
        !!this.Sensor.gateway)
    ) {
      const output = [];
      output.push({
        header: this.i18n.string('sensor_name'),
        items: [
          {
            text: this.Sensor.sensorName,
            value: null,
            valueType: null,
            category: this.i18n.string('sensor_name'),
            icon: null,
          },
        ],
      });
      if (!!this.Sensor.inletNumber)
        output.push({
          header: this.i18n.string('inlet_number'),
          items: [
            {
              text: formatSerialNumber(this.Sensor.inletNumber),
              value: null,
              valueType: null,
              category: this.i18n.string('inlet_number'),
              icon: null,
            },
          ],
        });
      if (!!this.Sensor.outletNumber)
        output.push({
          header: this.i18n.string('outlet_number'),
          items: [
            {
              text: formatSerialNumber(this.Sensor.outletNumber),
              value: null,
              valueType: null,
              category: this.i18n.string('outlet_number'),
              icon: null,
            },
          ],
        });
      if (!!this.Sensor.DAU)
        output.push({
          header: this.i18n.string('dau_code'),
          items: [
            {
              text: formatMAC(this.Sensor.DAU),
              value: null,
              valueType: null,
              category: this.i18n.string('dau_code'),
              icon: null,
            },
          ],
        });
      if (!!this.Sensor.gateway)
        output.push({
          header: this.i18n.string('gateway_code'),
          items: [
            {
              text: formatSerialNumber(this.Sensor.gateway),
              value: null,
              valueType: null,
              category: this.i18n.string('gateway_code'),
              icon: null,
            },
          ],
        });
      return output;
    }
    return null;
  }

  get GroupedAdditionalInfo(): {
    header: string;
    items: {
      text: string;
      value: number;
      valueType: number;
      category: string;
      icon: string;
      unitPrefix: string;
    }[];
  }[] {
    const groupBy = function (xs, key) {
      return xs.reduce(function (rv, x) {
        (rv[x[key]] = rv[x[key]] || []).push(x);
        return rv;
      }, {});
    };

    if (!this.asset) return [];
    const grouped = groupBy(this.asset.additionalInfo, 'category');
    return Object.keys(grouped).map((key) => {
      return {
        header: key,
        items: grouped[key],
      };
    });
  }

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }

  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }

  constructor(
    public i18n: I18nService,
    private ui: UiService,
    private router: Router
  ) {}

  ngOnInit() {
    this.asset.additionalDocuments.map((doc) => {
      if (!doc.name) {
        const startIndex = doc.storageLink.lastIndexOf('/');
        let docName = doc.storageLink.substring(startIndex + 1);
        const newIndex = docName.indexOf('.');
        let subString = docName.slice(0, newIndex);
        doc.name = subString;
      }
    });
  }

  openDocument(link: string) {
    window.open(link, '_blank', 'noreferrer');
    // noreferrer needed, otherwise the pdf reader in the new tab will freeze current tab for some reason
  }

  parseGroupHeader(header: string) {
    const parsed = this.i18n.parse(header);
    return parsed[this.i18n.MeasurementSystem]
      ? parsed[this.i18n.MeasurementSystem]
      : parsed;
  }
}
