<div id="Map-container">
  <div class="spinner-container" *ngIf="isLoading">
    <app-spinner></app-spinner>
  </div>
  <div class="inset-shadow-container"></div>
  <div class="find-devices-code" [class.mobile]="isMobile" [class.tablet]="isTablet" [class.desktop]="isDesktop">
    <ng-container *ngIf="isMobile">
      <div class="device-details">
        <div class="device-content-details first">
          <div (touchstart)="onSwipe($event, 'start')">
            <div class="device-content-icon">
              <i nz-icon [nzType]="isPanelOpen ? 'line' : 'line'" class="svg-icon"></i>
            </div>
            <span class="body-bold" style="font-size: 20px">{{
              i18n.string("map_panel")
              }}</span>
          </div>
          <div *ngIf="isPanelOpen" class="device-inner-content">
            <div class="inner-content-details bottom" *ngFor="let plant of sortedPlantList">
              <div class="one_row">
                <i nz-icon *ngIf="plant['icon'] == 'inactive'" class="svg_icon_inactive fillwhite"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'critical'" class="svg_icon_critical fillwhite"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'warning'" class="svg_icon_warning"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'working'" class="svg_icon_working fillgreen"
                  [nzType]="'icons:' + plant['icon']"></i>
                <h3 class="plant_name">{{ plant.objectName }}</h3>
                <div style="margin-left: auto" (click)="navigate(plant)">
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </div>
              </div>
              <p class="sub_class">{{ plant.Assets.length }} {{i18n.string("equipments")}}</p>
              <p class="sub_class">{{ plant.NumSensors }} {{i18n.string("sensors")}}</p>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
    <nz-collapse class="colexppanel" [nzBordered]="false" *ngIf="isDesktop">
      <nz-collapse-panel [nzHeader]="i18n.string('map_panel')" [nzActive]="isnzActive" [nzExtra]="extraTpl"
        nzShowArrow="false" class="body-bold arrowBg" (nzActiveChange)="showArrow($event)">
        <ng-container *ngIf="isDesktop">
          <div class="separation-line"></div>
          <div class="device-inner-content">
            <div class="inner-content-details" *ngFor="let plant of sortedPlantList" (click)="navigate(plant)">
              <div class="one_row">
                <i nz-icon *ngIf="plant['icon'] == 'inactive'" class="svg_icon_inactive fillwhite"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'critical'" class="svg_icon_critical fillwhite"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'warning'" class="svg_icon_warning"
                  [nzType]="'icons:' + plant['icon']"></i>
                <i nz-icon *ngIf="plant['icon'] == 'working'" class="svg_icon_working fillgreen"
                  [nzType]="'icons:' + plant['icon']"></i>
                <div class="plant_name">{{ plant.objectName }}</div>
                <div class="right-arrow">
                  <span nz-icon nzType="arrow-right" nzTheme="outline"></span>
                </div>
              </div>
              <p class="sub_class">{{ plant.Assets.length }} {{i18n.string("equip")}}. / {{ plant.NumSensors }}
                {{i18n.string("sensors")}}</p>
            </div>
          </div>
        </ng-container>
      </nz-collapse-panel>
    </nz-collapse>
  </div>
  <ng-template #extraTpl>
    <div class="device-content-icon">
      <i nz-icon [nzType]="isnzActive ? 'down' : 'up'" class="svg-icon"></i>
    </div>
  </ng-template>
  <div id="Map"></div>
</div>