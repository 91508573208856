export class OTObject {
  public objectID: number;
  public objectName: string;
  public objectTypeID: number;
  public objectType: string;
  public parentID: number;
  public objectStatus: string;
  public colorCode: string;
  public description: string;
  public isAsset: boolean;
  public isConnected: boolean;
  public isLifetimeConnected?: boolean;
  public warning: boolean;
  public critical: boolean;
  public inactive: boolean;
  public equipmentType: string;
  public childCount: number;
  public geoPosition: string;
  public children: OTObject[];
  public picture: string;
  public thumbnail: string;
  public curLayer: string;
  public nextLayer: string;
  public layerName: string;
  public layerID: number;
  public buildingPlan: string;
  public numSensors: number;
  public customerName: string;
  public sensorDigitIDs: string;
  public edgeDeviceIDs: string;
  public macs: string;
  public gatewayIDs: string;
  // temporary fix until archive ticket is deployed
  public isArchived?: boolean = false;
  public displayOrder?: number;
  public defaultImageId?: string;
}

export class AssetGeoData extends OTObject {
  public latitude: number;
  public longitude: number;
}

export class Country {
  public id: number;
  public name: {
    en: string;
    de: string;
  };
}

export class Notification {
  public notificationID: number;
  public title: string;
  public message: string;
  public instructions: string;
  public measurementTypeID: number;
  public measurementTitle: string;
  public objectID: number;
  public notificationStatusID: number;
  public notificationStatus: string;
  public objectName: string;
  public sensorName: string;
  public sensorID: number;
  public equipmentType: string;
  public colorCode: string;
  public isWarning: boolean;
  public isCritical: boolean;
  public inactive: boolean;
  public isArchived: boolean;
  public severity: number;
  public value: number;
  public prevValue: number;
  public measuredValue: string;
  public isEdgeDevice: boolean;
  public timestamp: Date;
  public breadcrumbs: OTObject[] = [];
  public expanded = true;
  public onlyApplyWorseStatus: boolean;
  public notifyAllChanges: boolean;

  constructor(data: Partial<Notification>, navs: OTObject[]) {
    Object.assign(this, data);
    this.gatherBreadcrumbs(navs);
  }

  private gatherBreadcrumbs(navigation: OTObject[]) {
    this.breadcrumbs = [];
    /*
    const firstBread = {
      ...this.object,
      parent: undefined,
      thumbnail: undefined,
      telemetry: undefined,
    };
    this.breadcrumbs.push(firstBread);*/
    const currentID = this.objectID;
    let nav = navigation.find((_nav) => _nav.objectID === currentID);
    // nav = navigation.find((_nav) => _nav.objectID === nav.parentID);
    let iterations = 0;
    const max = 20;
    while (!!nav && iterations < max) {
      iterations++;
      this.breadcrumbs = [
        {
          objectID: nav.objectID,
          objectName: nav.objectName,
          objectTypeID: nav.objectTypeID,
          objectType: nav.objectType,
          parentID: nav.parentID,
          objectStatus: nav.objectStatus,
          colorCode: nav.colorCode,
          isAsset: nav.isAsset,
          isConnected: nav.isConnected,
          isLifetimeConnected: nav.isLifetimeConnected,
          description: null,
          warning: nav.warning,
          critical: nav.critical,
          inactive: nav.inactive,
          isArchived: nav.isArchived,
          equipmentType: nav.equipmentType,
          childCount: nav.childCount,
          geoPosition: nav.geoPosition,
          children: nav.children,
          picture: nav.picture,
          thumbnail: nav.thumbnail,
          curLayer: nav.curLayer,
          nextLayer: nav.nextLayer,
          layerName: nav.layerName,
          layerID: nav.layerID,
          buildingPlan: nav.buildingPlan,
          numSensors: nav.numSensors,
          customerName: nav.customerName,
          edgeDeviceIDs: nav.edgeDeviceIDs,
          sensorDigitIDs: nav.sensorDigitIDs,
          macs: nav.macs,
          gatewayIDs: nav.gatewayIDs,
        },
        ...this.breadcrumbs,
      ];
      nav = navigation.find((_nav) => _nav.objectID === nav.parentID);
    }
  }
}

export class ExpandableNotification extends Notification {
  expanded: boolean;
}
export class SensorThreshold {
  public sensorID: number;
  public sensorMeasurementTypeID: number;
  public thresholdMin: number;
  public thresholdMax: number;
  public warning: boolean;
  public error: boolean;
}

export function getDefaultImage(
  equipmentType: string,
  rotatingEqDefaultImageId?: string
): string {
  switch (equipmentType.toLowerCase()) {
    case 'flange':
      return 'assets/images/default_equipments/Flange.png';
    case 'pipe':
      return 'assets/images/default_equipments/Pipe.png';
    case 'steamtrap':
    case 'steam trap':
      return 'assets/images/default_equipments/Steamtrap.png';
    case 'tank':
      return 'assets/images/default_equipments/Tank.png';
    case 'valve':
      return 'assets/images/default_equipments/Valve.png';
    case 'Rotating Equipment':
      if (rotatingEqDefaultImageId == 'rotatingeqCentrifuge') {
        return 'assets/images/Loctite-pulse-smart-Centrifuge.png';
      } else if (rotatingEqDefaultImageId == 'rotatingeqCompressor') {
        return 'assets/images/Loctite-pulse-smart-Compressor.png';
      } else if (rotatingEqDefaultImageId == 'rotatingeqMotor') {
        return 'assets/images/Loctite-pulse-smart-Motor.png';
      } else if (rotatingEqDefaultImageId == 'rotatingeqFan') {
        return 'assets/images/Loctite-pulse-smart-Fan.png';
      } else if (rotatingEqDefaultImageId == 'rotatingeqGearbox') {
        return 'assets/images/Loctite-pulse-smart-Gear-box.png';
      } else if (rotatingEqDefaultImageId == 'rotatingeqPump') {
        return 'assets/images/Loctite-pulse-smart-Pump.png';
      } else {
        return 'assets/images/default_equipments/rotatingEqu.png';
      }
    default:
      return 'assets/images/default_sensor.png';
  }
}
