<nz-select
  nzShowSearch
  #addInfo
  [(ngModel)]="selected"
  (ngModelChange)="onChange($event)"
  [class.selected]="selected && selected !== ''"
  name="addInfo"
  nzPlaceHolder="{{ placeholder }}"
  [nzCustomTemplate]="addInfoTemplate"
  [nzShowArrow]="false"
  [nzAllowClear]="optional"
  [nzClearIcon]="inputClearTpl"
  [disabled]="disabled"
>
  <div class="input-area input-close">
    <nz-option
      *ngFor="let option of options"
      [nzValue]="option"
      [nzLabel]="i18n.Parse(option)"
    ></nz-option>
  </div>
</nz-select>
<ng-template #addInfoTemplate let-selected>
  <div class="customSelectBox">
    <span>{{ selected.nzLabel }}</span>
    <i
      *ngIf="!addInfo.optional"
      nz-icon
      [nzType]="addInfo.nzOpen ? 'icons:dropup-arrow' : 'icons:dropdown-arrow'"
      class="customSelectIcon"
    ></i>
  </div>
</ng-template>
<ng-template #inputClearTpl>
  <div class="customClearIcon">
    <i nz-icon [nzType]="'icons:exit'" (click)="inputValue = null"></i>
  </div>
</ng-template>
