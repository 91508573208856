<div class="splash-screen">
  <div class="reveal" *ngIf="!error">
    <i nz-icon nzType="icons:loading" class="svg-icon fillwhite"></i>
  </div>
</div>
<section class="splash-screen" *ngIf="error"> 
  <div class="container">
  <div class="error-image-container">
    <img src="assets/images/splash.png">
  </div>
  <div class="error-wrapper">
  <div class="content-main">{{ i18n.String("connection_error") }}</div>
  <div class="error-container">
    <app-nav-button  [primary]="true" svg_icon="icons:reset"  [href]="refreshPage">{{ i18n.String("network_issue") }}</app-nav-button>
  </div>
</div>
</div>
</section>