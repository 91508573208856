import { HttpClient } from '@angular/common/http';
import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  ViewChild,
} from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { MsalService } from '@azure/msal-angular';
import { forkJoin, Subject, timer } from 'rxjs';

import { AuthenticationService } from '../../../cc-framework/services/authentication.service';
import { HostUnreachableException } from '../../../cc-framework/services/web.exceptions';
import { environment } from '../../../environments/environment';
import { AppComponent } from '../../app.component';
import { I18nService } from '../../services/i18n.service';
import { NotificationService } from '../../services/notification.service';
import { UiService } from '../../services/ui.service';
import { AssetsService } from './../../services/assets.service';
import { SettingsService } from './../../services/settings.service';
import { UtilService } from './../../services/util.service';

const ERROR_MESSAGE_LOGIN_DEACTIVATED = 'Login Deactivated';
const ERROR_MESSAGE_ROLE_NOT_AUTHORIZED = 'Role not authorized';
const ERROR_MESSAGE_PROMPT_SSO_CONVERSION_QUERY = 'Prompt SSO Conversion Query';
@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit, OnDestroy {
  // ref for resetting password field
  @ViewChild('password') passwordField: ElementRef;

  // form data
  public user: string = '';
  public pw: string = '';
  public email: string = '';
  public passwordVisible = false;

  // page state
  returnUrl: string;
  isLoading = false;
  error = false;
  isOffline = false;
  loginForm: FormGroup;
  forgotPassword = false;
  pwChanged = false;
  signedUp = false;
  hasSendPWForgotMail = false;
  unknownMailError = false;
  sendMailError = false;
  acc_blocked = false;
  role_not_authorized = false;
  sso_conversion_query = false;
  temp_ssotoken = ''; // only used during sso conversion process
  temp_disposable_login = null;
  temp_disposable_msal_login = null;
  country: string;

  private readonly _destroying$ = new Subject<void>();

  get isMobile(): boolean {
    return this.ui.IsMobile;
  }
  get isTablet(): boolean {
    return this.ui.IsTablet;
  }
  get isDesktop(): boolean {
    return this.ui.IsDesktop;
  }
  get credentialLogin(): boolean {
    return environment.credentialLogin;
  }

  get portalLogin(): boolean {
    return environment.portalLogin;
  }

  get msalLogin(): boolean {
    return environment.msalLogin;
  }

  get debugMode(): boolean {
    return environment.debugMode;
  }

  get disableSignup(): boolean {
    return environment.disableSignup;
  }

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private auth: AuthenticationService,
    private msalAuth: MsalService,
    private assets: AssetsService,
    private settings: SettingsService,
    private util: UtilService,
    private ui: UiService,
    private _fb: FormBuilder,
    private notifications: NotificationService,
    public i18n: I18nService,
    private app: AppComponent,
    private http: HttpClient
  ) {}

  get I18N(): I18nService {
    return this.i18n;
  }

  get InstallationAgentURL(): string {
    return environment.installation_agent_url;
  }

  ngOnInit() {
    this.ui.onNavBack(null);
    this.ui.setBackText('');
    let refAuth = null;
    this.route.params.subscribe((params) => {
      if (!!params['token']) {
        refAuth = params['token'];
        if (!!params['args']) {
          let redirectArgs = null;
          try {
            redirectArgs = JSON.parse(atob(params['args']));
          } catch (e) {
            redirectArgs = null;
            console.error(e);
          }
          if (!!redirectArgs)
            this.returnUrl = redirectArgs.targetUrl + '/' + params['args'];
        }
      } else {
        if (!!params['key']) {
          const key = '' + params['key'];
          if (key === 'pw-changed') this.pwChanged = true;
          if (key === 'signed-up') this.signedUp = true;
        }
        if (!!params['mail']) {
          this.forgotPassword = true;
          this.hasSendPWForgotMail = false;
          this.email = params['mail'];
          // setTimeout(() => this.onSendPWForgotMail());
        }
      }
    });
    this.route.queryParams.subscribe((params) => {
      this.country = params['country'];
    });
    this.loginForm = this._fb.group({
      userName: ['', [Validators.required, Validators.minLength(3)]],
      password: ['', [Validators.required, Validators.minLength(3)]],
      email: ['', []],
    });
    // get return url from route parameters or default to '/menu'
    this.returnUrl = this.route.snapshot.queryParams['returnUrl'] || '/menu';

    // if there's a return url specified in the auth service, we choose that one
    if (this.auth.returnUrl) this.returnUrl = this.auth.returnUrl;

    // handle redirects from remote id provider
    if (this.route.snapshot.fragment && environment.portalLogin) {
      const fragments = this.route.snapshot.fragment.split('&');
      const parameters = {};
      fragments.forEach((element) => {
        const pair = element.split('=');
        parameters[pair[0]] = pair[1];
      });

      if (environment.debugAuth) parameters['debug_mode'] = 'true';

      this.onLoadingStarted();
      this.authorizeRemoteLogin(parameters);
    } else {
      if (this.auth.isLoggedIn() || refAuth) {
        this.onLoadingStarted();
        this.auth.checkLoginStatus(refAuth).subscribe(
          (isLoggedIn) => {
            this.onLoadingFinished();
            if (isLoggedIn) this.router.navigate([this.returnUrl]);
            else {
              // silently log out
              this.auth.logout();
              this.isLoading = false;
            }
          },
          (exception) => {
            this.onLoadingFinished(true);
            if (exception instanceof HostUnreachableException) {
              this.router.navigate([this.returnUrl]);
            } else {
              this.onException(exception);
            }
          }
        );
      } else {
        this.isLoading = false;
      }
    }

    this.onLoadingStarted();
    this.msalAuth.instance
      .handleRedirectPromise()
      .then((result) => {
        this.onLoadingFinished();
        if (!!result && !!result.idToken)
          this.authorizeMsalLogin(result.idToken);
      })
      .catch((err) => {
        this.onLoadingFinished();
        console.error(err);
      });
  }
  ngOnDestroy(): void {
    this._destroying$.next(undefined);
    this._destroying$.complete();
  }

  loginViaMSAL() {
    if (environment.msalPopup) {
      this.msalAuth.loginPopup().subscribe({
        next: (result) => {
          this.authorizeMsalLogin(result.idToken);
        },
        error: (error) => console.log(error),
      });
    } else {
      this.msalAuth.loginRedirect();
    }
  }

  login() {
    this.onLoadingStarted();

    if (!!this.temp_disposable_login) this.temp_disposable_login.unsubscribe();
    this.temp_disposable_login = this.auth
      .loginAtServerWithCredentials({
        user: this.user,
        pw: this.pw,
      })
      .subscribe(
        (result) => {
          // preload plants and user settings after login
          forkJoin([
            this.assets.getRegions(),
            // Not needed. The tab bar is always visible and fetches current notifications, 2023-02-06, LKlum
            // this.assets.getNotifications(),
            this.settings.getUserSettings(),
            timer(1500),
          ]).subscribe(
            () => {
              this.onLoadingFinished();
              this.user = '';
              this.pw = '';
              this.notifications.subscribeToNotifications();
              this.router.navigate([this.returnUrl]);
            },
            (error) => {
              this.onLoadingFinished(error);
            }
          );
        },
        (error) => {
          this.onLoadingFinished(error);
        }
      );
  }

  remoteLogin() {
    this.onLoadingStarted();
    this.auth.loginAtProvider();
  }

  debugLogin() {
    this.onLoadingStarted();
    this.auth
      .loginAtServerWithCredentials({
        user: !!environment.debugCredentials
          ? environment.debugCredentials.user
          : '',
        pw: !!environment.debugCredentials
          ? environment.debugCredentials.pw
          : '',
      })
      .subscribe(
        (result) => {
          // preload plants and user settings after login
          forkJoin([
            this.assets.getRegions(),
            // Not needed. The tab bar is always visible and fetches current notifications, 2023-02-06, LKlum
            // this.assets.getNotifications(),
            this.settings.getUserSettings(),
            timer(1500),
          ]).subscribe(() => {
            this.onLoadingFinished();
            this.user = '';
            this.pw = '';
            this.notifications.subscribeToNotifications();
            this.router.navigate([this.returnUrl]);
          });
        },
        (error) => {
          this.onLoadingFinished(error);
        }
      );
  }

  signupLogin() {
    if (this.country === 'us') {
      this.router.navigate(['/signup'], { queryParams: { country: 'us' } });
    } else {
      this.router.navigate(['/signup']);
    }
  }

  authorizeRemoteLogin(parameters) {
    this.auth.loginAtServer(parameters).subscribe(() => {
      // horrible hack, please improve:
      const redirect = localStorage.getItem('redirect-return-url');
      if (redirect) {
        localStorage.removeItem('redirect-return-url');
        window.location.href = redirect;
      } else {
        this.notifications.subscribeToNotifications();
        this.router.navigate([this.returnUrl]);
      }
    });
  }

  authorizeMsalLogin(access_token: string, convert: boolean = false) {
    this.onLoadingStarted();
    this.temp_ssotoken = access_token;

    if (!!this.temp_disposable_msal_login)
      this.temp_disposable_msal_login.unsubscribe();
    this.temp_disposable_msal_login = this.auth
      .loginViaMsal(access_token, convert)
      .subscribe(
        (result) => {
          this.temp_ssotoken = '';
          this.msalAuth.instance['redirectResponse'] = null; // this is necessary to prevent a repeat of the redirect when the user navigates back to this page via the browser's back button, after a logout, and the browser restores the page using bfcache
          // supposedly, the redirectResponse should be cleared automatically in this case, according to this:
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/issues/4171 and this:
          // https://github.com/AzureAD/microsoft-authentication-library-for-js/releases/tag/msal-browser-v2.22.1
          //... but it isn't

          // preload plants and user settings after login
          console.warn('called twice');
          forkJoin([
            this.assets.getRegions(),
            // Not needed. The tab bar is always visible and fetches current notifications, 2023-02-06, LKlum
            // this.assets.getNotifications(),
            this.settings.getUserSettings(),
            timer(1500),
          ]).subscribe(() => {
            this.onLoadingFinished();
            this.user = '';
            this.pw = '';
            this.notifications.subscribeToNotifications();
            this.router.navigate([this.returnUrl]);
          });
        },
        (error) => {
          if (error.error !== ERROR_MESSAGE_PROMPT_SSO_CONVERSION_QUERY)
            this.temp_ssotoken = ''; // we only need this during the sso conversion prompt
          this.onLoadingFinished(error);
        }
      );
  }

  SSOConversionPromptAccepted() {
    this.sso_conversion_query = false;
    this.authorizeMsalLogin(this.temp_ssotoken, true);
  }

  SSOConversionPromptDeclined() {
    this.sso_conversion_query = false;
    this.temp_ssotoken = '';
  }

  onSendPWForgotMail() {
    if (this.email !== '') {
      this.unknownMailError = false;
      this.sendMailError = false;
      this.util.setLoadingStarted(); // show splash screen for preloading plants and user settings after login
      forkJoin([
        this.auth.requestForgotPWMail(this.email),
        timer(1500),
      ]).subscribe(
        () => {
          this.util.setLoadingFinished(); // show splash screen for preloading plants and user settings after login
          this.hasSendPWForgotMail = true;
        },
        (error) => {
          if (error.status === 401) {
            // explanation: for security reasons we do not want the user to know wether the email address he entered exists in the system
            this.util.setLoadingFinished(); // show splash screen for preloading plants and user settings after login
            this.hasSendPWForgotMail = true;
          } else this.sendMailError = true;
          console.error(error);
          setTimeout(() => this.util.setLoadingFinished(), 1000); // show splash screen for preloading plants and user settings after login
        }
      );
    }
  }
  PWForgotMailNotReceived() {
    this.hasSendPWForgotMail = false;
  }

  onLoadingStarted() {
    this.isLoading = true;
    this.error = false;
    this.util.setLoadingStarted(); // show splash screen for preloading plants and user settings after login
  }
  onLoadingFinished(error = null) {
    this.isLoading = false;
    if (error == null) {
      error = false;
    }
    if (error.error === ERROR_MESSAGE_ROLE_NOT_AUTHORIZED) {
      this.role_not_authorized = true;
      this.sso_conversion_query = false;
      this.acc_blocked = false;
      error = false;
    } else if (error.error == ERROR_MESSAGE_LOGIN_DEACTIVATED) {
      this.role_not_authorized = false;
      this.sso_conversion_query = false;
      this.acc_blocked = true;
      error = false;
    } else if (error.error == ERROR_MESSAGE_PROMPT_SSO_CONVERSION_QUERY) {
      this.role_not_authorized = false;
      this.acc_blocked = false;
      this.sso_conversion_query = true;
      error = false;
    } else {
      this.role_not_authorized = false;
      this.acc_blocked = false;
      this.sso_conversion_query = false;
      this.error = !!error;
    }
    this.util.setLoadingFinished(); // hide splash screen
  }

  onHostReachable(url: string) {
    this.isOffline = false;
  }

  onHostUnreachable(url: string) {
    this.isOffline = true;
    this.onLoadingFinished(true);
    // TODO: display host unreachable error and possible enter offline-mode
  }

  onException(exception) {
    this.onLoadingFinished(true);
    // TODO: display error
  }

  onUnauthorized() {
    this.onLoadingFinished();
    // TODO: display 'login failed'
    this.router.navigate(['/login']);
  }

  onSubmitUser() {
    this.passwordField.nativeElement.focus();
  }

  onSubmitPW() {
    this.login();
  }

  toLegalImprint() {
    this.router.navigate(['/legal-imprint']);
  }
  toTermsOfUse() {
    this.country === 'us'
      ? this.router.navigate(['us/terms-of-use'])
      : this.router.navigate(['/terms-of-use']);
  }
  toDataProtectionDeclaration() {
    this.country === 'us'
      ? this.router.navigate(['us/data-protection'])
      : this.router.navigate(['/data-protection']);
  }
  toServiceConditions() {
    this.router.navigate(['/service-conditions']);
  }
  toNoteForUsResidents() {
    this.router.navigate(['/note-for-us-residents']);
  }
  toCookieConsent() {
    this.router.navigate(['/cookie-consent']);
  }
  toCookiesSettings() {
    this.callCookieBanner(['OptanonConsent', 'OptanonAlertBoxClosed'], '', 0);
    this.app.callingScript();
  }
  callCookieBanner(cname, cvalue, exMins) {
    for (let i = 0; i < cname.length; i++) {
      var d = new Date();
      d.setTime(d.getTime() + exMins * 60 * 1000);
      var expires = 'expires=' + d.toUTCString();
      document.cookie =
        cname[i] +
        '=' +
        cvalue +
        ';' +
        expires +
        ';path=/' +
        ';domain=' +
        window.location.hostname;
    }
  }
  toprivacyPolicy() {
    this.country === 'us'
      ? (window.location.href =
          'https://www.henkel-northamerica.com/privacy-statement-na?view=content-blank')
      : '';
  }
  toCAPrivacyRights() {
    this.country === 'us'
      ? (window.location.href =
          'https://www.henkel-northamerica.com/ca-privacy-rights?pageID=564690')
      : '';
  }
  toDSAR() {
    this.country === 'us'
      ? (window.location.href =
          'https://henkelprivacy.exterro.net/portal/dsar.htm?target=henkelprivacy')
      : '';
  }
}
