<app-header
  [transparent]="false"
  [title]="
    !!asset
      ? !Sensor
        ? i18n.parse(asset.objectName)
        : i18n.string('equipment_status')
      : i18n.string('asset')
  "
  [backTo]="!!asset ? '' : ''"
>
</app-header>
<div class="spinner-container" [class.desktop]="isDesktop" *ngIf="!!loading">
  <app-spinner [black]="true"></app-spinner>
</div>

<div
  class="content"
  [class.mobile]="isMobile"
  [class.tablet]="isTablet"
  [class.desktop]="isDesktop"
  [class.sensorView]="Sensor"
>
  <div *ngIf="isDesktop" class="desktop-sidebar white-sidebar">
    <app-sidebar
      [showList]="true"
      [Asset]="asset"
      [sensorID]="selectedSensor"
      [loading]="loading"
    ></app-sidebar>
  </div>

  <div
    [class.desktop-main]="isDesktop"
    [class.white-sidebar-main]="isDesktop"
    *ngIf="!loading"
  >
    <app-breadcrumbs
      [array]="Breadcrumbs"
      [isAsset]="true"
      *ngIf="!isDesktop && !!asset && asset.breadcrumbs && !breadcrumbsLoading"
      class="detail-bold"
    >
    </app-breadcrumbs>
    <app-spinner
      class="crumbs-spinner"
      [black]="true"
      *ngIf="!!asset && breadcrumbsLoading"
    ></app-spinner>

    <h1 *ngIf="!!asset && !Sensor" class="asset-name">
      {{ asset.objectName }}
    </h1>
    <p *ngIf="!!asset && !Sensor" class="asset-overview-subtitle">
      AssetID# {{ asset.objectID }}
    </p>

    <div
      *ngIf="!!asset && Sensor && !isDesktop && asset.equipmentTypeID != 113"
      class="sensor-header flat-sensor sensor-header-title"
    >
      <ng-container *ngIf="!!SensorDetailedTelemetry">
        <!-- <i
          nz-icon
          [nzType]="'icons:' + SensorDetailedTelemetry.icon"
          class="sensor-icon svg-icon"
        ></i> -->
        <div class="sensor-header-inner-div">
          <!-- <h2>{{ i18n.parse(SensorDetailedTelemetry.title) }}</h2>
          <span class="detail- lighter-label">
            {{ Sensor.sensorDisplayLabel }}
          </span> -->
          <h1>{{ Sensor.sensorDisplayLabel }}</h1>
        </div>
      </ng-container>
      <ng-container *ngIf="!SensorDetailedTelemetry">
        <i
          nz-icon
          *ngIf="!!Sensor.telemetry[0]"
          [nzType]="'icons:' + Sensor.telemetry[0].svg"
          class="sensor-icon svg-icon"
        ></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.parse(Sensor.telemetry[0].title) }}</h2>
          <span class="detail lighter-label">{{
            Sensor.sensorDisplayLabel
          }}</span>
        </div>
      </ng-container>
    </div>

    <ng-container
      *ngIf="!!asset && Sensor && isDesktop && asset.equipmentTypeID != 113"
    >
      <div class="asset-back-btn" (click)="onSensorDeselect()">
        <i nz-icon nzType="icons:arrow-left" class="svg-icon fillwhite"></i>
        <span>{{
          i18n.string("back_to") + i18n.parse(this.asset.objectName)
        }}</span>
      </div>
      <ng-container *ngIf="!!SensorDetailedTelemetry">
        <div class="sensor-header">
          <h2>{{ i18n.parse(Sensor.sensorDisplayLabel) }}</h2>
        </div>
        <p class="asset-overview-subtitle">AssetID# {{ asset.objectID }}</p>
      </ng-container>
      <ng-container *ngIf="!SensorDetailedTelemetry">
        <h2 class="sensorViewTitle">
          {{ Sensor.sensorDisplayLabel }}
        </h2>
      </ng-container>
    </ng-container>

    <h1 *ngIf="error">{{ i18n.string("asset_not_found") }}</h1>
    <div class="header-img-container">
      <img
        *ngIf="!!asset && !!asset.picture"
        nz-image
        nzDisablePreview
        (click)="onHeaderImageClick()"
        class="header-img"
        [class.hiddenPicture]="thumbnailLoading || thumbnailError"
        [nzSrc]="asset['picture']"
        alt="Asset"
        (load)="onThumbnailLoaded($event)"
        (error)="onThumbnailLoadFailed($event)"
      />
      <ng-container
        *ngFor="let marker of sensorMarkers; trackBy: trackByMarker"
      >
        <div
          class="sensor-marker-wrapper"
          [ngStyle]="{ 'left.px': marker.x, 'top.px': marker.y }"
        >
          <div
            class="sensor-marker"
            [class.longtext]="marker.text.length > 4"
            [class.warning]="marker.warning"
            [class.critical]="marker.critical"
            [class.inactive]="marker.inactive"
            [class.nodata]="marker.nodata"
            [class.selected]="marker.selected"
            (click)="onSensorSelect(marker.sensorID)"
            [class.hidden]="marker.hidden"
          >
            <span>{{ marker.text }}</span>
            <div *ngIf="marker.critical" class="alarm-marker">
              <i
                nz-icon
                nzType="icons:status-exclamation-triangle"
                class="mini-svg-icon fillwhite"
              ></i>
            </div>
            <div *ngIf="marker.warning" class="warning-marker">
              <i
                nz-icon
                nzType="icons:status-exclamation-octagon"
                class="mini-svg-icon"
              ></i>
            </div>
            <div *ngIf="marker.inactive" class="inactive-marker">
              <i
                nz-icon
                nzType="icons:cross-circle"
                class="svg-icon fillwhite"
              ></i>
            </div>
            <div
              *ngIf="
                !marker.critical &&
                !marker.warning &&
                !marker.inactive &&
                marker.selected
              "
              class="sub-marker"
            >
              <span>{{ marker.text }}</span>
            </div>
          </div>
        </div>
      </ng-container>
      <div class="thumbnailLoadingSpinnerWrapper" *ngIf="thumbnailLoading">
        <app-spinner [black]="true"></app-spinner>
      </div>
    </div>

    <div *ngIf="!rotatingAssetDetails.length && isRotating">
      <app-spinner [black]="true"></app-spinner>
    </div>
    <div *ngIf="rotatingAssetDetails.length">
      <ng-container *ngFor="let rotatingAsset of rotatingAssetDetails">
        <ng-container
          *ngIf="
            !!asset &&
            asset.notifications &&
            asset.notifications.length > 0 &&
            asset.equipmentTypeID != 113
          "
        >
          <ng-container *ngFor="let notification of Notifications">
            <app-notification-alert
              *ngIf="notification.isCritical || notification.isWarning"
              [notification]="notification"
              [notificationList]="false"
            ></app-notification-alert>
          </ng-container>
        </ng-container>
        <div class="sensor-header">
          <i nz-icon nzType="icons:pressure" class="sensor-icon svg-icon"></i>
          <h2>{{ i18n.string("health_status") }}</h2>
        </div>
        <div
          class="rotating-equipment"
          *ngIf="
            rotatingAssetWorkingFineStatus.includes(
              rotatingAsset.healthStatus.toLowerCase()
            )
          "
        >
          <div class="ga-sensor-health-fine detail-bold">
            <i
              nz-icon
              nzType="icons:status-check"
              class="mini-svg-icon fillgreen"
            ></i>
            <span>{{ i18n.string("working_fine") }}</span>
          </div>
        </div>
        <div
          class="rotating-equipment"
          *ngIf="
            rotatingAssetWarningStatus.includes(
              rotatingAsset.healthStatus.toLowerCase()
            )
          "
        >
          <div class="ga-sensor-health-check-warning detail-bold">
            <i
              nz-icon
              nzType="icons:status-exclamation-octagon"
              class="mini-svg-icon fillyellow"
            ></i>
            <span>{{ i18n.string("warning") }}</span>
          </div>
        </div>
        <div
          class="rotating-equipment"
          *ngIf="
            rotatingAsset.healthStatus.toLowerCase() ===
            'needs immediate attention'
          "
        >
          <div class="ga-sensor-health-check detail-bold">
            <i
              nz-icon
              nzType="icons:status-exclamation-triangle"
              class="mini-svg-icon fillred"
            ></i>
            <span>{{ i18n.string("critical") }}</span>
          </div>
        </div>
        <div
          class="rotating-equipment"
          *ngIf="
            rotatingAssetInactiveStatus.includes(
              rotatingAsset.healthStatus.toLowerCase()
            )
          "
        >
          <div class="ga-sensor-health-inactive detail-bold">
            <i
              nz-icon
              nzType="icons:cross-circle"
              class="mini-svg-icon fillblackcoral"
            ></i>
            <span>{{ i18n.string("inactive") }}</span>
          </div>
        </div>
        <div class="sensor-header">
          <i nz-icon nzType="icons:tool" class="sensor-icon svg-icon"></i>
          <h2>{{ i18n.string("remaining_useful_life") }}</h2>
        </div>
        <div class="rotating-equipment">
          <div class="rotating-equipment-inner-content">
            <span class="rotating-equipment-content-bold">
              {{ i18n.string("rul_bold_content") }}
            </span>
            <span class="rotating-equipment-content">
              {{ i18n.string("rul_content") }}
            </span>
          </div>
          <div class="rul-image">
            <!-- <img nz-image nzDisablePreview [nzSrc]="'assets/images/rul_image.svg'" alt="RUL image" /> -->
            <nz-progress
              [nzPercent]="rotatingAsset.lifePercentage"
              [nzSteps]="10"
              nzStrokeColor="#00AA75"
              [nzShowInfo]="false"
            ></nz-progress>
            <span
              >{{ rotatingAsset.lifePercentage }} {{ i18n.string("rul_image") }}
            </span>
          </div>
        </div>
        <div class="more-details">
          <app-nav-button
            [primary]="true"
            (click)="goToNanoPreciseDashboard()"
            svg_icon="icons:dashboard"
          >
            {{ i18n.string("analyst_dashboard") }}
          </app-nav-button>
        </div>

        <app-additional-info
          [Sensor]="Sensor"
          [groupedInfoValues]="groupedInfoValues"
        ></app-additional-info>
      </ng-container>
    </div>
    <ng-container
      *ngIf="
        !!asset &&
        asset.notifications &&
        asset.notifications.length > 0 &&
        asset.equipmentTypeID != 113 &&
        !isRotating
      "
    >
      <ng-container *ngFor="let notification of Notifications">
        <app-notification-alert
          *ngIf="notification.isCritical || notification.isWarning"
          [notification]="notification"
          [notificationList]="false"
        ></app-notification-alert>
      </ng-container>
    </ng-container>

    <div
      class="location-wrapper"
      *ngIf="
        !!asset &&
        !!Sensor &&
        asset.notifications &&
        asset.notifications.length > 0
      "
    >
      <div class="sensor-header">
        <i nz-icon nzType="icons:location" class="sensor-icon svg-icon"></i>
        <h2>{{ i18n.string("location") }}</h2>
      </div>
      <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
        <app-spinner [black]="true"></app-spinner>
      </div>

      <div class="location-content">
        <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
          <app-spinner [black]="true"></app-spinner>
        </div>

        <nz-descriptions
          *ngIf="asset.breadcrumbs && !breadcrumbsLoading"
          [nzColumn]="1"
          nzBordered
          [nzSize]="'small'"
          class="nzNotATable"
        >
          <ng-container
            *ngFor="let breadcrumb of asset.breadcrumbs; let i = index"
          >
            <nz-descriptions-item
              *ngIf="i > 0"
              [nzTitle]="i18n.parse(breadcrumb.layerName)"
            >
              <span
                [class.clickable]="isBreadcrumbClickable(breadcrumb)"
                (click)="navToBreadcrumb(breadcrumb)"
              >
                {{ i18n.parse(breadcrumb.objectName) }}
              </span>
            </nz-descriptions-item>
          </ng-container>
        </nz-descriptions>
      </div>
    </div>
    <ng-container *ngIf="!!Sensor">
      <app-asset-overview-map
        *ngIf="!!Sensor.geoPosition"
        [SensorPosition]="sensorGeoPosition"
        [Sensor]="Sensor"
      >
      </app-asset-overview-map>
    </ng-container>
    <ng-container *ngIf="!!Sensor">
      <div class="sensor-header flat-sensor">
        <i nz-icon [nzType]="'icons:graph'" class="sensor-icon svg-icon"></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("sensor_data") }}</h2>
          <span class="black-coral-text body-bold">{{
            Sensor.sensorDisplayLabel
          }}</span>
        </div>
      </div>
      <div
        class="sensordata-wrapper"
        *ngIf="!!SensorDetailedTelemetry && SensorDetailedTelemetry.length > 0"
      >
        <app-sensor-value
          [telemetry]="SensorDetailedTelemetry[0]"
          [graphData]="getVisibleGraphs(SensorDetailedTelemetry[0])[0]"
          [asHeader]="true"
        >
        </app-sensor-value>
        <div class="grapharea-wrapper">
          <app-tab-bar
            [opaque]="true"
            [icon]="false"
            (tabChanged)="
              onTelemetryTimeframeSelect($event, SensorDetailedTelemetry[0])
            "
            [disabled]="
              noDataTelemetry(SensorDetailedTelemetry[0]) ||
              graphBrushActive ||
              getTimeframe(SensorDetailedTelemetry[0]).custom
            "
          >
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="
                isTelemetryTimeframeCustom(SensorDetailedTelemetry[0])
              "
            >
              {{ i18n.string("days") }}</app-tab-bar-item
            >
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="
                isTelemetryTimeframeCustom(SensorDetailedTelemetry[0])
              "
            >
              {{ i18n.string("weeks") }}</app-tab-bar-item
            >
            <app-tab-bar-item
              class="one-of-three"
              [inactive]="
                isTelemetryTimeframeCustom(SensorDetailedTelemetry[0])
              "
            >
              {{ i18n.string("months") }}</app-tab-bar-item
            >
          </app-tab-bar>
          <div
            *ngFor="
              let telemetry of SensorDetailedTelemetry
                ? SensorDetailedTelemetry
                : [];
              let i = index
            "
          >
            <ng-container *ngIf="!getVisibleGraphs(telemetry).length">
              <div class="graph-wrapper">
                <app-spinner [black]="true"></app-spinner>
              </div>
            </ng-container>
            <ng-container *ngFor="let data of getVisibleGraphs(telemetry)">
              <div
                class="range-picker-backdrop"
                *ngIf="data.openRangePicker > 0"
                (click)="cancelRangePick(data)"
              ></div>
              <div
                *ngIf="i === 0"
                class="range-picker-wrapper"
                [class.disabled]="noDataTelemetry(telemetry)"
              >
                <ng-container *ngIf="isDesktop; else mobileDatePicker">
                  <nz-range-picker
                    [nzShowToday]="false"
                    [nzAllowClear]="false"
                    [nzPlaceHolder]="[i18n.string('start'), i18n.string('end')]"
                    [nzDisabledDate]="rangePickerDisabledDate(data, 1)"
                    #datePick
                    [(ngModel)]="range"
                    [id]="'picker_start_' + data.metadata.measurementTypeID"
                    (ngModelChange)="onRangePickerSelect($event, data)"
                  >
                  </nz-range-picker>
                </ng-container>
                <ng-template #mobileDatePicker>
                  <nz-date-picker
                    [nzShowToday]="false"
                    [nzAllowClear]="false"
                    #datePick
                    [id]="'picker_start_' + data.metadata.measurementTypeID"
                    [nzDisabledDate]="rangePickerDisabledDate(data, 1)"
                    [(ngModel)]="range[0]"
                    (ngModelChange)="
                      onRangeStartPick(
                        data.metadata.measurementTypeID,
                        Sensor.sensorID,
                        $event,
                        'picker_end_' + data.metadata.measurementTypeID,
                        data
                      )
                    "
                  >
                  </nz-date-picker>
                  <nz-date-picker
                    [nzShowToday]="false"
                    [nzAllowClear]="false"
                    #datePick
                    [id]="'picker_end_' + data.metadata.measurementTypeID"
                    [nzDisabledDate]="rangePickerDisabledDate(data, 2)"
                    [(ngModel)]="range[1]"
                    (ngModelChange)="
                      onRangeEndPick(
                        data.metadata.measurementTypeID,
                        Sensor.sensorID,
                        $event,
                        data
                      )
                    "
                  >
                  </nz-date-picker>
                  <button>
                    <span
                      [class.active]="data.openRangePicker === 1"
                      (click)="
                        openRangePicker(
                          'picker_start_' + data.metadata.measurementTypeID,
                          data
                        )
                      "
                      >{{ range[0] | date : DateFormat }}</span
                    >
                    -
                    <span
                      [class.active]="data.openRangePicker === 2"
                      (click)="
                        openRangePicker(
                          'picker_end_' + data.metadata.measurementTypeID,
                          data
                        )
                      "
                      >{{ range[1] | date : DateFormat }}</span
                    >
                    <i
                      nz-icon
                      nzType="icons:edit"
                      class="sensor-icon svg-icon"
                      [class.fillgrey]="noData(data)"
                      (click)="
                        openRangePicker(
                          'picker_start_' + data.metadata.measurementTypeID,
                          data
                        )
                      "
                    ></i>
                  </button>
                </ng-template>
              </div>

              <div class="hidden-graph">
                <div *ngIf="SensorDetailedTelemetry.length > 1">
                  <div class="graph-separator"></div>
                  <p class="graph-title">
                    {{ i18n.parse(telemetry.title) }} |
                    {{ telemetry.value.valStr }}
                  </p>
                </div>
                <div class="graph-wrapper">
                  <app-spinner
                    *ngIf="!data || !!data.loading"
                    [black]="true"
                  ></app-spinner>
                  <app-graph
                    [hasResetButton]="i === SensorDetailedTelemetry.length - 1"
                    [Data]="data"
                    *ngIf="!data.error"
                    [class.hidden]="!!data.loading"
                    (brushStateChange)="graphBrushActive = $event"
                    (timeframeUpdated)="dateRangeUpdatedfromGraph($event)"
                    [Timeframe]="getTimeframe(telemetry)"
                    [Settings]="settings"
                    [Type]="!!data.co_metadata ? 'multi-line' : 'line'"
                    (timeframeReset)="resetTimeframeSelect(telemetry)"
                  >
                  </app-graph>
                  <span *ngIf="!data.loading && !!data.error" class="error">{{
                    i18n.string("error_loading_data")
                  }}</span>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
      </div>
    </ng-container>
    <ng-container *ngIf="!Sensor && asset.equipmentTypeID != 113">
      <ng-container *ngFor="let group of !!asset ? SensorTiles : []">
        <div *ngIf="group && group.title != 'Temperature'">
          <div
            class="sensor-position-details"
            [class.desktop]="isDesktop"
            [class.mobile]="!isDesktop"
          >
            <div class="image" *ngIf="IsPipe">
              <img
                nz-image
                nzDisablePreview
                class="find-sensor-image"
                [nzSrc]="
                  isDesktop
                    ? 'assets/images/Underground.png'
                    : 'assets/images/Aboveground.png'
                "
                alt="Find Sensor"
              />
            </div>
            <div class="content" *ngIf="IsPipe">
              <div class="info-icon">
                <img
                  nz-image
                  nzDisablePreview
                  class="find-sensor-image"
                  [nzSrc]="'assets/icons/info.svg'"
                  alt="Find Sensor"
                />
              </div>
              <div class="find-sesor-description">
                <strong>{{ i18n.string("Find_sensor_ques") }}</strong
                >{{ i18n.string("Find_sensor_position") }}
              </div>
            </div>
          </div>
          <div class="sensor-header">
            <i
              nz-icon
              [nzType]="'icons:' + group.icon"
              class="sensor-icon svg-icon"
            ></i>
            <h2>{{ i18n.string("sensor_status") }}</h2>
          </div>
          <div class="tile-wrapper">
            <ng-container *ngFor="let telemetry of group.telemetry">
              <app-tile
                [telemetry]="telemetry"
                [isSteamTrap]="HasSteamTrapAnalytics"
                [isLeakageSensor]="HasLeakageSensors"
                [class.steamTrap]="HasSteamTrapAnalytics"
                [isSteamTrapInletOutlet]="IsSteamTrap && !HasSteamTrapAnalytics"
                [steamtrap_outlet]="SteamTrapOutlet"
                [asset]="asset"
                (click)="onSensorSelect(telemetry.sensor.sensorID)"
              ></app-tile>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </ng-container>
    <ng-container
      *ngIf="!Sensor && HasSteamTrapAnalytics && asset.equipmentTypeID != 113"
      )
    >
      <div class="sensor-header flat-sensor">
        <i nz-icon [nzType]="'icons:pressure'" class="sensor-icon svg-icon"></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("steam_trap_monitoring") }}</h2>
          <span *ngIf="showHighTempText()" class="detail lighter-label">{{
            i18n.string("high_heat_resistance")
          }}</span>
        </div>
      </div>
      <app-steam-trap-analytics
        [GraphDisplays]="SteamTrapGraphDisplays"
        [GraphData]="SteamTrapGraphData"
        [loading]="SteamTrapAnalyticsLoading"
        [SteamTrapTelemetry]="SteamTrapTelemetry"
        [isTelemetryTimeframeCustom]="IsTelemetryTimeframeCustom"
        [Range]="Range"
        [settings]="settings"
        [NoPricePerTon]="SteamTrapNoPricePerTon"
        [SteamLossCurrencyUnit]="SteamLossCurrencyUnit"
        (OnTelemetryTimeframeSelect)="
          onTelemetryTimeframeSelect($event.index, $event.telemetry)
        "
        (dateRangeUpdatedfromGraph)="dateRangeUpdatedfromGraph($event)"
        (OnRangeStartPick)="
          onRangeStartPick(
            $event.measurementTypeID,
            $event.sensorID,
            $event.event,
            $event.endPickerID,
            $event.data
          )
        "
        (OnRangeEndPick)="
          onRangeEndPick(
            $event.measurementTypeID,
            $event.sensorID,
            $event.event,
            $event.data
          )
        "
        (OpenRangePicker)="openRangePicker($event.id, $event.data)"
        (OnRangePickerSelect)="onRangePickerSelect($event.event, $event.data)"
      >
      </app-steam-trap-analytics>
    </ng-container>
    <ng-container *ngFor="let group of !!asset && !Sensor ? Temperature : []">
      <div class="sensor-header flat-sensor">
        <i
          nz-icon
          [nzType]="'icons:' + group.icon"
          class="sensor-icon svg-icon"
        ></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("temperature_of_the_edge_device") }}</h2>
          <!--<span class="detail lighter-label">{{ i18n.string('temperature_of_the_edge_device') }}</span>-->
        </div>
      </div>
      <ng-container *ngFor="let telemetry of group.telemetry">
        <div aria-label="temperature detail" class="edgeTemperatureButton">
          <app-sensor-value
            [telemetry]="telemetry"
            (click)="
              toSensorValue(
                telemetry.sensor.sensorID,
                telemetry.measurementTypeID
              )
            "
          ></app-sensor-value>
        </div>
      </ng-container>
    </ng-container>
    <div class="history-wrapper" *ngIf="historyItems && !!Sensor">
      <div class="sensor-header flat-sensor">
        <i nz-icon nzType="icons:history" class="sensor-icon svg-icon"></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("history") }}</h2>
          <span class="black-coral-text body-bold" *ngIf="Sensor">{{
            Sensor.sensorDisplayLabel
          }}</span>
        </div>
      </div>
      <div class="history">
        <nz-list nzBordered [nzFooter]="historyfooter" class="history-list">
          <nz-list-item *ngFor="let item of HistoryItems.slice(0, 10)">
            <app-history-item
              [dateFormat]="settings.DateFormat"
              [detailedhistory]="true"
              [item]="item"
              [asset]="asset"
            >
            </app-history-item>
          </nz-list-item>
        </nz-list>

        <ng-template #historyfooter>
          <div class="history-footer-item">
            <app-nav-button
              [darksecondary]="true"
              svg_icon="icons:documentation"
              (click)="overlayDocumentation = true"
            >
              {{ i18n.string("add_documentation") }}</app-nav-button
            >
            <app-docu-wizard
              *ngIf="overlayDocumentation"
              [assetId]="asset.objectID"
              [sensorId]="selectedSensor"
              (saved)="onDocumentationAdded($event)"
              (close)="overlayDocumentation = false"
            ></app-docu-wizard>
          </div>
          <div class="history-footer-item">
            <app-nav-button
              [primary]="true"
              svg_icon="icons:eye"
              [disabled]="!HistoryItems || HistoryItems.length === 0"
              (click)="toSensorHistory(!!Sensor ? Sensor.sensorID : null)"
            >
              {{ i18n.string("show_all_entries") }}</app-nav-button
            >
          </div>
        </ng-template>
      </div>
    </div>
    <!---Rotating equipment history section-->
    <div class="history-wrapper" *ngIf="historyItems.length > 0 && isRotating">
      <div class="sensor-header flat-sensor">
        <i nz-icon nzType="icons:history" class="sensor-icon svg-icon"></i>
        <div class="sensor-header-inner-div">
          <h2>{{ i18n.string("history") }}</h2>
          <span class="black-coral-text body-bold" *ngIf="Sensor">{{
            Sensor.sensorDisplayLabel
          }}</span>
        </div>
      </div>
      <div class="history">
        <nz-list nzBordered [nzFooter]="historyfooter" class="history-list">
          <nz-list-item *ngFor="let item of HistoryItems.slice(0, 10)">
            <app-history-item
              [dateFormat]="settings.DateFormat"
              [detailedhistory]="true"
              [item]="item"
              [asset]="asset"
            >
            </app-history-item>
          </nz-list-item>
        </nz-list>

        <ng-template #historyfooter>
          <!-- <div class="history-footer-item">
            <app-docu-wizard *ngIf="overlayDocumentation" [assetId]="asset.objectID" [sensorId]="selectedSensor"
              (saved)="onDocumentationAdded($event)" (close)="overlayDocumentation = false"></app-docu-wizard>
          </div> -->
          <div class="history-footer-item">
            <app-nav-button
              [primary]="true"
              svg_icon="icons:eye"
              [disabled]="!HistoryItems || HistoryItems.length === 0"
              (click)="toSensorHistory(!!Sensor ? Sensor.sensorID : null)"
            >
              {{ i18n.string("show_all_entries") }}</app-nav-button
            >
          </div>
        </ng-template>
      </div>
    </div>

    <div
      class="location-wrapper"
      *ngIf="
        !!asset &&
        (!asset.notifications || !asset.notifications.length > 0 || !Sensor) &&
        asset.equipmentTypeID != 113
      "
    >
      <div class="sensor-header">
        <i nz-icon nzType="icons:location" class="sensor-icon svg-icon"></i>
        <h2>{{ i18n.string("location") }}</h2>
      </div>
      <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
        <app-spinner [black]="true"></app-spinner>
      </div>

      <div class="location-content">
        <div class="location-spinner-container" *ngIf="breadcrumbsLoading">
          <app-spinner [black]="true"></app-spinner>
        </div>

        <nz-descriptions
          *ngIf="asset.breadcrumbs && !breadcrumbsLoading"
          [nzColumn]="1"
          nzBordered
          [nzSize]="'small'"
          class="nzNotATable"
        >
          <ng-container
            *ngFor="let breadcrumb of asset.breadcrumbs; let i = index"
          >
            <nz-descriptions-item
              *ngIf="i > 0"
              [nzTitle]="i18n.parse(breadcrumb.layerName)"
            >
              <span
                [class.clickable]="isBreadcrumbClickable(breadcrumb)"
                (click)="navToBreadcrumb(breadcrumb)"
                >{{ i18n.parse(breadcrumb.objectName) }}
              </span>
            </nz-descriptions-item>
          </ng-container>
        </nz-descriptions>
      </div>
    </div>
    <ng-container
      *ngIf="
        !!asset &&
        (!asset.notifications || !asset.notifications.length > 0) &&
        !!Sensor &&
        !!Sensor.geoPosition &&
        asset.equipmentTypeID != 113
      "
    >
      <app-asset-overview-map
        [SensorPosition]="sensorGeoPosition"
        [Sensor]="Sensor"
      >
      </app-asset-overview-map>
    </ng-container>
    <!--
    <div class="sensor-header"
      *ngIf="!!asset && !Sensor && (asset.additionalInfo.length > 0 || asset.additionalDocuments.length > 0)">
      <i nz-icon nzType="icons:additional-information" class="sensor-icon svg-icon"></i>
      <h2>{{i18n.string('additional_information')}}</h2>
    </div>
    <div class="additionalInformation"
      *ngIf="!!asset && (asset.additionalInfo.length > 0 || asset.additionalDocuments.length > 0)">
      <nz-collapse [nzBordered]="false" [nzExpandIconPosition]="'right'">
        <nz-collapse-panel #pA [nzHeader]="panelheaderA" [nzActive]="addInfo[0]" [nzExtra]="collapseIconA"
          nzShowArrow="false" *ngIf="asset.additionalInfo.length > 0">
          <nz-list nzSize="small" *ngFor="let group of GroupedAdditionalInfo">
            <nz-list-header class="body-bold-cap">{{i18n.parse(group.header)}}</nz-list-header>
            <nz-list-item *ngFor="let item of group.items" class="regular">
              <div><i nz-icon [nzType]="'icons:' + item.icon" *ngIf="!!item.icon"></i>
                <ng-container *ngIf="!!item.text">
                  {{i18n.parse(item.text)}}
                </ng-container>
                <ng-container *ngIf="!!item.value">
                  {{item.value | unit:item.valueType : i18n.MeasurementSystem}}
                </ng-container>
              </div>
            </nz-list-item>
          </nz-list>
          <ng-template #panelheaderA>
            <div class="collapse-header">
              <i nz-icon [nzType]="'icons:info-about-equip'"></i>
              <span class="body-bold">{{i18n.string('information_about_equipment')}}</span>
            </div>
          </ng-template>
          <ng-template #collapseIconA let-active>
            <i nz-icon *ngIf="!pA.nzActive" [nzType]="'icons:plus'"></i>
            <i nz-icon *ngIf="pA.nzActive" [nzType]="'icons:minus'"></i>
          </ng-template>
        </nz-collapse-panel>

        <nz-collapse-panel #pB [nzHeader]="panelheaderB" [nzActive]="addInfo[0]" [nzExtra]="collapseIconB"
          nzShowArrow="false" style="
        border: none;" *ngIf="asset.additionalDocuments.length > 0">
          <div class="image-wrapper">
            <div class="single-image-wrapper" *ngFor="let doc of asset.additionalDocuments">
              <span class="body-bold-cap">{{doc.name}}</span>
              <button class="photo-subwrapper" *ngIf="doc.type === 'image'">
                <img class="photo-preview" [src]="doc.thumbnailLink" (click)="overlayImage = doc.storageLink" />
                <div class="photo-icon-circle">
                  <i nz-icon nzType="icons:eye" class="svg-icon mini-icon"></i>
                  <span class="body-bold">{{i18n.string('show')}}</span>
                </div>
              </button>
              <button class="photo-subwrapper" *ngIf="doc.type === 'file'" (click)="openDocument(doc.storageLink)">
                <div class="photo-icon-circle">
                  <i nz-icon nzType="icons:eye" class="svg-icon mini-icon"></i>
                  <span class="body-bold">{{i18n.string('show')}}</span>
                </div>
                <div class="file-preview"><i nz-icon nzType="icons:file" class="svg-icon mini-icon"></i></div>
              </button>
            </div>
          </div>
          <ng-template #panelheaderB>
            <div class="collapse-header">
              <i nz-icon [nzType]="'icons:documents'"></i>
              <span class="body-bold">{{i18n.string('additional_documents')}}</span>
            </div>
          </ng-template>
          <ng-template #collapseIconB let-active>
            <i nz-icon *ngIf="!pB.nzActive" [nzType]="'icons:plus'"></i>
            <i nz-icon *ngIf="pB.nzActive" [nzType]="'icons:minus'"></i>
          </ng-template>
        </nz-collapse-panel>
      </nz-collapse>
    </div>
    <nz-modal [(nzVisible)]="overlayImage" (nzOnCancel)="overlayImage = null" [nzFooter]="null"
      [nzCloseIcon]="'icons:exit'" [nzTitle]="i18n.string('photo')">
      <img class="photo" src="{{ overlayImage }}" />
    </nz-modal>
    -->
    <app-additional-info
      [asset]="asset"
      [Sensor]="Sensor"
      [groupedInfoValues]="groupedInfoValues"
    ></app-additional-info>
    <button
      *ngIf="!!asset && !Sensor && this.SensorHealth.length"
      class="ga-sensor-health-btn"
      (click)="toSensorHealth()"
    >
      <h4>{{ i18n.string("show_sensor_health") }}</h4>
      <div>
        <div
          *ngIf="isSensorHealthCrit()"
          class="ga-sensor-health-check detail-bold"
        >
          <i
            nz-icon
            nzType="icons:status-exclamation-triangle"
            class="mini-svg-icon fillred"
          ></i>
          <span>{{ i18n.string("please_check") }}</span>
        </div>
        <div
          *ngIf="isSensorHealthWarning() && !isSensorHealthCrit()"
          class="ga-sensor-health-check-warning detail-bold"
        >
          <i
            nz-icon
            nzType="icons:status-exclamation-octagon"
            class="mini-svg-icon fillyellow"
          ></i>
          <span>{{ i18n.string("please_check") }}</span>
        </div>
        <div
          *ngIf="!isSensorHealthCrit() && !isSensorHealthWarning()"
          class="ga-sensor-health-fine detail-bold"
        >
          <i
            nz-icon
            nzType="icons:status-check"
            class="mini-svg-icon fillgreen"
          ></i>
          <span>{{ i18n.string("working_fine") }}</span>
        </div>
        <div class="ga-sensor-health-time detail-bold">
          <i
            nz-icon
            nzType="icons:refresh"
            class="mini-svg-icon fillblackcoral"
          ></i>
          <span class="align-right">
            {{
              lastChecked()
                ? i18n.string("ago1") + lastChecked() + i18n.string("ago2")
                : i18n.string("never_checked")
            }}
          </span>
        </div>
      </div>
    </button>
    <div class="techmargin">
      <app-nav-button
        *ngIf="
          asset?.equipmentType === 'Steam Trap' ||
          asset?.equipmentType === 'Flange' ||
          asset?.equipmentType === 'Rotating Equipment'
        "
        [darksecondary]="true"
        svg_icon="icons:tool"
        (click)="toTechnicalDocuments()"
      >
        {{ i18n.string("view_technical_documents") }}</app-nav-button
      >
    </div>
    <!--<div class="refresh-button-wrapper">
      <app-nav-button [primary]="false" [darksecondary]="true" svg_icon="icons:refresh"
        (click)="refresh(asset.objectID, true)">{{i18n.string('refresh_and_update')}}</app-nav-button>
    </div>-->
  </div>
</div>
