import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

import { StorageService } from '../../cc-framework/services/storage.service';
import { I18N_LEGALS } from '../i18n/legals';
import { I18N_STRINGS } from '../i18n/strings';
import { UserSettings } from '../model/settings';
import { SettingsService } from './settings.service';
import { environment } from '../../environments/environment';

@Injectable({
  providedIn: 'root',
})
export class I18nService {
  private measurementSystem = 'mt';
  private language = browserLang();
  private languageFetchedSubject = new Subject<void>();
  constructor(
    public settings: SettingsService,
    public storage: StorageService
  ) {
    if (this.storage.Token && this.storage.Token.length) {
      this.settings.getUserSettings().subscribe((result: UserSettings) => {
        this.updateUserSettings(result);
      });
    }

    this.settings.onSettingsChanged().subscribe((result: UserSettings) => {
      this.updateUserSettings(result);
    });
    this.settings
      .onSettingsChangedAfterGet()
      .subscribe((result: UserSettings) => {
        this.updateUserSettings(result);
      });
  }
  get MeasurementSystem(): string {
    return this.measurementSystem;
  }
  get Lng(): string {
    return this.language;
  }
  public String(key: string): string {
    if (I18N_STRINGS[key]) {
      return I18N_STRINGS[key] ? I18N_STRINGS[key][this.language] : '';
    } else if (I18N_LEGALS[key]) {
      return I18N_LEGALS[key] ? I18N_LEGALS[key][this.language] : '';
    } else {
      console.error("unable to find translation for key: '" + key + "'");
    }
  }

  public string(key: string): string {
    return this.String(key);
  }

  public onLanguageFetched(): Observable<void> {
    return this.languageFetchedSubject;
  }

  public updateUserSettings(settings: UserSettings) {
    this.measurementSystem = settings.MeasurementSystem.abbreviation;
    this.language = settings.Language.abbreviation;
    this.languageFetchedSubject.next();
  }

  public Parse(source: any): string {
    try {
      const parsed = JSON.parse(source);
      source = parsed;
    } catch {}
    if (typeof source !== 'number' && !source) return '';
    if (typeof source === 'string') return source;
    if (typeof source === 'number') return '' + source;
    if (!!source[this.language]) return source[this.language];
    if (!!source[environment.locale]) return source['en'];
    console.error('unable to parse', source);
    return '';
  }

  public parse(source: any): string {
    return this.Parse(source);
  }
}

function browserLang(): string {
  let lang = navigator.language || navigator['userLanguage'];
  lang = lang ? lang.slice(0, 2) : 'en';
  if (lang !== 'de' && lang !== 'en' && lang !== 'pt') lang = 'en';
  return lang;
}
