import 'rxjs/add/operator/map';

import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { MsalService } from '@azure/msal-angular';
import { Base64 } from 'js-base64';
import { of, Subject } from 'rxjs';
import { Observable } from 'rxjs/Observable';
import { catchError, map, tap } from 'rxjs/operators';

import { AssetsService } from '../../app/services/assets.service';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { UnauthorizedException } from './web.exceptions';

declare const window: any;
// import { catchError, map, tap } from 'rxjs/operators';
@Injectable()
export class AuthenticationService {
  token_settings = {
    response_type: 'token id_token',
    scope: 'openid',
    claims: 'com pos loc fnm lnm dob', // company, position, location, first name, last name, date of birth
    state: 'xyz',
  };

  // array of callback functions to be called when the data model has changed
  private loginCallbacks: ((boolean) => any)[] = [];
  public returnUrl: string;

  constructor(
    private http: HttpClient,
    private storage: StorageService,
    private assetsService: AssetsService,
    private msalAuth: MsalService
  ) {}

  loginAtProvider(redirect?: string): Promise<any> {
    // login by sending an identification request to a remote id provider
    return new Promise<any>((resolve, reject) => {
      const url =
        environment.authorization_uri +
        '&response_type=' +
        this.token_settings.response_type +
        '&client_id=' +
        environment.client_id +
        '&scope=' +
        this.token_settings.scope +
        '&claims=' +
        this.token_settings.claims +
        '&state=' +
        this.token_settings.state +
        '&nonce=' +
        this.get_nonce() +
        '&redirect_uri=' +
        environment.redirect_uri;

      window.location.href = url;
      resolve(null);
    });
  }

  loginAtServerWithCredentials(
    credentials: { user: string; pw: string },
    overwrite_Username?: string
  ): Observable<any> {
    return this.http
      .post(
        environment.host + 'api/auth/loginViaCredentials',
        {},
        {
          headers: {
            Authorization:
              'Basic ' +
              Base64.encode(
                Base64.encode(credentials.user) +
                  ':' +
                  Base64.encode(credentials.pw)
              ),
          },
          responseType: 'json',
        }
      )
      .pipe(
        tap((response) => {
          if (overwrite_Username) response['user'] = overwrite_Username;
          if (response['user'] && response['js_token']) {
            this.storage.UserName = response['user']; // this has to be done before inserting anything else into user storage
            this.storage.Token = response['js_token'];
            this.executeCallbacks(response['user']);
          }
        })
      );
  }

  checkTokenScope(scope: string): boolean {
    try {
      const token = this.storage.Token;
      const token_split = token.split('.');
      const token_decode = Base64.decode(token_split[1]);
      const token_data = JSON.parse(token_decode.replace(/\'/g, '"'));
      return token_data['scope'].indexOf(scope) > -1;
    } catch (e) {
      return false;
    }
  }

  IsSSOUser(): boolean {
    try {
      const token = this.storage.Token;
      const token_split = token.split('.');
      const token_decode = Base64.decode(token_split[1]);
      const token_data = JSON.parse(token_decode.replace(/\'/g, '"'));
      return token_data['isSSOUser'] === 'True';
    } catch (e) {
      return false;
    }
  }

  loginAtServer(parameters: any, overwrite_Username?: string): Observable<any> {
    return this.http
      .post(environment.host + 'api/auth/AuthorizeAccessToken', parameters, {
        headers: { Authorization: this.storage.Token },
      })
      .pipe(
        tap((response) => {
          while (typeof response === 'string') {
            response = JSON.parse(response);
          }
          if (overwrite_Username) response['user'] = overwrite_Username;
          if (response['user'] && response['js_token']) {
            this.storage.UserName = response['user']; // this has to be done before inserting anything else into user storage
            this.storage.Token = response['js_token'];
            this.executeCallbacks(response['user']);
          }
        })
      );
  }

  loginViaMsal(
    access_token: string,
    convert: boolean = false
  ): Observable<any> {
    return this.http
      .post(
        environment.host + 'api/auth/AuthorizeMsalToken',
        {
          ConvertToSSOUser: convert,
        },
        {
          headers: { Authorization: access_token },
        }
      )
      .pipe(
        tap((response) => {
          while (typeof response === 'string') {
            response = JSON.parse(response);
          }
          if (response['user'] && response['js_token']) {
            this.storage.UserName = response['user']; // this has to be done before inserting anything else into user storage
            this.storage.Token = response['js_token'];
            this.executeCallbacks(response['user']);
          }
        })
      );
  }

  loginViaToken(token: string): Observable<boolean> {
    const parameters = {
      js_token: token,
    };
    const max_repeats = 6;
    const request_sub = new Subject<number>();
    const response_sub = new Subject<any>();

    request_sub.subscribe((repeat_counter) => {
      this.http
        .post(environment.host + 'api/auth/loginViaToken', parameters, {
          headers: { Authorization: token },
        })
        .pipe(
          tap((response) => {
            this.storage.UserName = response['user']; // this has to be done before inserting anything else into user storage
            this.storage.Token = token;
            response_sub.next(true);
          }),
          catchError((error) => {
            if (repeat_counter >= max_repeats) {
              if (error instanceof UnauthorizedException)
                response_sub.next(false);
              else response_sub.error(error);
            } else {
              request_sub.next(repeat_counter + 1);
            }
            return of(null);
          })
        );
    });

    request_sub.next(0);
    return response_sub;
  }

  requestForgotPWMail(email: string): Observable<any> {
    const temp = window.location.href.split('/'); // used to pass application host information to service
    const baseTags = document.getElementsByTagName('base');
    const basePath = baseTags.length
      ? baseTags[0].href.substr(location.origin.length, 999)
      : '';
    let host = temp[0] + '//' + temp[2] + basePath;
    if (host.lastIndexOf('/') === host.length - 1) {
      host = host.slice(0, host.length - 1);
    }
    const parameters = {
      app_id: environment.AppID,
      email: email,
      appHost: host,
    };
    return this.http.post(
      environment.host + 'api/auth/forgotPW',
      parameters,
      {}
    );
  }

  checkForgotPWToken(token: string): Observable<any> {
    const parameters = {
      app_id: environment.AppID,
      js_token: token,
      scope: 'change-pw',
    };

    return this.http
      .post(environment.host + 'api/auth/checkForgotPWToken', parameters, {
        headers: { Authorization: this.storage.Token },
      })
      .pipe(
        map((result) => true),
        catchError((error) => {
          if (error instanceof UnauthorizedException) of(false); // TODO: untested
          throw error;
        })
      );
  }

  setNewPasswordViaEmail(token: string, password: string): Observable<any> {
    const parameters = {
      app_id: environment.AppID,
      js_token: token,
      scope: 'change-pw',
      pw: password,
    };
    return this.http
      .post(environment.host + 'api/auth/changePW', parameters, {
        headers: { Authorization: this.storage.Token },
      })
      .pipe(
        map((result) => true),
        catchError((error) => {
          if (error instanceof UnauthorizedException) of(false); // TODO: untested
          throw error;
        })
      );
  }
  setNewPasswordViaSettings(
    current_pw: string,
    new_pw: string
  ): Observable<any> {
    const parameters = {
      app_id: environment.AppID,
      current_pw: current_pw,
      new_pw: new_pw,
    };
    return this.http
      .post(environment.host + 'api/settings/changePW', parameters, {
        headers: { Authorization: this.storage.Token },
      })
      .pipe(
        map((result) => true),
        catchError((error) => {
          if (error instanceof UnauthorizedException) of(false); // TODO: untested
          throw error;
        })
      );
  }

  checkLoginStatus(token: string = null): Observable<any> {
    if (!!token) this.storage.Token = token;
    const parameters = {
      app_id: environment.AppID,
      username: this.storage.UserName,
      js_token: this.storage.Token,
      scope: 'db-access',
    };

    return this.http
      .post(environment.host + 'api/auth/loginViaToken', parameters, {
        headers: { Authorization: this.storage.Token },
      })
      .pipe(
        map((result) => true),
        catchError((error) => {
          if (error instanceof UnauthorizedException) of(false); // TODO: untested
          throw error;
        })
      );
  }

  isLoggedIn(): boolean {
    if (this.storage.Token) {
      window.adobeDataLayer[0]
        ? (window.adobeDataLayer[0].userAccount['loginStatus'] = 'logged in')
        : undefined;
    }
    try {
      return !!this.storage.Token && !!this.storage.Token.length;
    } catch (e) {
      window.adobeDataLayer[0]
        ? (window.adobeDataLayer[0].userAccount['loginStatus'] = 'logged out')
        : undefined;
      return false;
    }
  }

  logout(
    explicit: boolean = false,
    callback?: (any?) => any,
    returnUrl?: string
  ) {
    const isMSALLoggedIn = this.isMSALLoggedIn();
    window.adobeDataLayer[0]
      ? (window.adobeDataLayer[0].userAccount['loginStatus'] = 'logged out')
      : undefined;
    this.returnUrl = returnUrl;

    try {
      if (isMSALLoggedIn && explicit) {
        // we only logout from MSAL if the user explicitly logs out, no need to do it if the token is expired
        this.msalLogout(
          () => {
            this.storage.Token = null;
            this.storage.UserName = null; // this has to happen last because of the way the storage is organized by user
            localStorage.clear();
            sessionStorage.clear();

            this.assetsService.clearCache();
          },
          () => {
            if (callback) callback();
          }
        );
      } else {
        this.storage.Token = null;
        this.storage.UserName = null; // this has to happen last because of the way the storage is organized by user
        this.assetsService.clearCache();
        if (callback) callback();
      }
    } catch (e) {
      console.error('msal logout failed', e);
      this.storage.Token = null;
      this.storage.UserName = null; // this has to happen last because of the way the storage is organized by user
      this.assetsService.clearCache();
    }
  }

  isMSALLoggedIn(): boolean {
    return this.msalAuth.instance.getAllAccounts().length > 0;
  }

  msalLogout(callbackBefore?: (any?) => any, callbackAfter?: (any?) => any) {
    const currentAccount = this.msalAuth.instance.getAccountByUsername(
      this.storage.UserName
    );
    if (callbackBefore) callbackBefore();
    // If we were sure that the redirect worked flawlessly, we could simply use an await here.
    // ...but this does not seem to be the case for Office 365 logins.
    // Instead, the user is stuck on a different page and when the user navigates back to this page, bfcache will ruin our chances
    // of returning to the login page, so we have to use a timeout.
    setTimeout(() => {
      const redirect_uri = environment.redirect_uri;
      this.msalAuth.instance.logoutRedirect({
        account: currentAccount,
        logoutHint: currentAccount?.idTokenClaims?.login_hint,
        postLogoutRedirectUri: redirect_uri,
        authority:
          environment.msalIssuerService + environment.msalIssuerServiceTenant,
      });
    });

    if (callbackAfter) callbackAfter();
  }

  public signup(formValues, lang) {
    const temp = window.location.href.split('/'); // used to pass application host information to service
    const baseTags = document.getElementsByTagName('base');
    const basePath = baseTags.length
      ? baseTags[0].href.substr(location.origin.length, 999)
      : '';
    let host = temp[0] + '//' + temp[2] + basePath;
    if (host.lastIndexOf('/') === host.length - 1) {
      host = host.slice(0, host.length - 1);
    }
    const parameters = {
      email: formValues.email,
      name: formValues.name,
      surname: formValues.surname,
      customer: formValues.customerentity,
      designation: formValues.designation,
      country: formValues.country,
      password: formValues.choosepswd,
      lang: lang,
      appHost: host,
    };
    return this.http
      .post(environment.host + 'api/auth/signup', parameters, {})
      .pipe(
        map((result) => {
          true;
        }),
        catchError((error) => {
          if (error instanceof UnauthorizedException) of(false); // TODO: untested
          throw error;
        })
      );
  }
  public loadUserApprovalData(token: string): Observable<any> {
    // uses special registration auth token containing the registration id, no user login required
    return this.http.get(environment.host + 'api/auth/approvaldata', {
      headers: { Authorization: token },
    });
  }

  public processRegistrationRequest(
    access_granted: boolean,
    customer: number,
    token: string
  ): Observable<any> {
    const temp = window.location.href.split('/'); // used to pass application host information to service
    const baseTags = document.getElementsByTagName('base');
    const basePath = baseTags.length
      ? baseTags[0].href.substr(location.origin.length, 999)
      : '';
    let host = temp[0] + '//' + temp[2] + basePath;
    if (host.lastIndexOf('/') === host.length - 1) {
      host = host.slice(0, host.length - 1);
    }
    return this.http.post(
      environment.host + 'api/auth/process-registration',
      { access_granted: access_granted, customer: customer, appHost: host },
      {
        headers: { Authorization: token },
      }
    );
  }

  public registerLoginCallback(callback: (any?) => any): number {
    return this.loginCallbacks.push(callback) - 1; // push returns length. we want last id, which is one less
  }
  public unregisterLoginCallback(ref: number) {
    // TODO: deal with growing array
    this.loginCallbacks[ref] = null;
  }

  private get_nonce(): number {
    return Math.random();
  }

  private executeCallbacks(username: string) {
    for (let i = 0; i < this.loginCallbacks.length; i++) {
      if (this.loginCallbacks[i])
        setTimeout(() => this.loginCallbacks[i](username), 1);
    }
  }
}
