import { Notification, OTObject } from './object';
import {
  CurrentTelemetry,
  ParseHealthFromRawSensorTelemetry,
  ParseSensorsFromRawSensorTelemetry,
  ParseSteamTrapFromRawSensorTelemetry,
  ParseTemperatureFromRawSensorTelemetry,
  Sensor,
  TypedSensorTelemetry,
} from './sensor';
import { MeasurementSystemSettings } from './settings';

const DIRECT_C_LEAKAGE_ID = 1026;
const STEAM_TRAP_STATUS_ID = 1068;
const STEAM_TRAP_STEAM_LOSS_AMOUNT_ID = 1069;
const STEAM_TRAP_STEAM_LOSS_MASS_ID = 1070;
const STEAM_TRAP_AGGREGATED_STATUS_ID = 1075; // DEPRECTATED
const INACTIVE_LEAKAGE_ID = 1078;
const INACTIVE_STEAM_TRAP_ID = 1079;
const INACTIVE_STEAM_TRAP_ANALYTICS_ID = 1080;
export class Asset extends OTObject {
  constructor(obj: OTObject, public breadcrumbs) {
    super();
    Object.assign(this, obj);
  }
}
export class AssetDetail extends Asset {
  public thumbnail: string = null;
  public sensors: Sensor[] = [];

  public health: CurrentTelemetry[];
  public steamTrap: CurrentTelemetry[];
  public temperature: CurrentTelemetry;

  public notifications: Notification[];

  public additionalInfo: {
    text: string;
    value: number;
    valueType: number;
    category: string;
    icon: string;
    unitprefix: string;
  }[];

  public additionalDocuments: {
    type: string;
    storageLink: string;
    thumbnailLink: string;
    name: string;
  }[];

  public static FromServiceResponse(response: any): AssetDetail {
    if (!response.isAsset)
      console.warn('WARNING: trying to parse asset from non-asset object.');

    const sensors = ParseSensorsFromRawSensorTelemetry(response.telemetry);
    const health = ParseHealthFromRawSensorTelemetry(response.telemetry);
    const temperature = ParseTemperatureFromRawSensorTelemetry(
      response.telemetry
    );
    const steamTrap = ParseSteamTrapFromRawSensorTelemetry(response.telemetry);

    steamTrap.map((item) => {
      const sensor = sensors.find(
        (_sensor) => _sensor.sensorID === item.sensor.sensorID
      );
      sensor.telemetry.map((telemetry) => {
        telemetry.critical = item.critical;
        telemetry.warning = item.warning;
        telemetry.inactive = item.inactive;
      });
    });

    return Object.assign(
      new AssetDetail(
        {
          objectID: response.objectID,
          objectName: response.objectName,
          objectTypeID: response.objectTypeID,
          objectType: response.objectType,
          parentID: response.parent.parentID,
          objectStatus: response.objectStatus,
          description: response.description,
          colorCode: response.colorCode,
          isAsset: true,
          isConnected: response.isConnected,
          isLifetimeConnected: response.isLifetimeConnected,
          warning: response.warning,
          critical: response.critical,
          inactive: response.inactive,
          isArchived: response.isArchived,
          equipmentType: response.equipmentType,
          childCount: null,
          geoPosition: response.geoPosition,
          children: [],
          picture: response.picture,
          thumbnail: response.thumbnail,
          curLayer: response.curLayer,
          nextLayer: response.nextLayer,
          layerName: response.layerName,
          layerID: response.layerID,
          buildingPlan: response.buildingPlan,
          numSensors: response.numSensors,
          customerName: response.customerName,
          sensorDigitIDs: response.sensorDigitIDs,
          edgeDeviceIDs: response.edgeDeviceIDs,
          macs: response.macs,
          gatewayIDs: response.gatewayIDs,
        },
        [] // breadcrumbs are added later in assets service
      ),
      {
        thumbnail: null,
        sensors: sensors,
        health: health,
        temperature: temperature,
        steamTrap: steamTrap,
        notifications: response.notifications.map(
          (item) => new Notification(item, [])
        ),
        additionalInfo: response.additionalInfo,
        additionalDocuments: response.additionalDocuments,
      }
    );
  }

  constructor(obj: OTObject, breadcrumbs) {
    super(obj, breadcrumbs);
    Object.assign(this, obj);

    // telemetry status override (set telemetry status according to notifications)
    this.sensors = this.sensors.map((sensor: Sensor) => {
      sensor.telemetry = this.telemetryStatusOverride(sensor.telemetry);
      return sensor;
    });
    this.health = this.telemetryStatusOverride(this.health);
    this.temperature = this.telemetryStatusOverride([this.temperature])[0];
    this.critical = !!this.sensors.find((sensor) => sensor.Critical);
    this.warning =
      !!this.sensors.find((sensor) => sensor.Warning) && !this.critical;
  }

  telemetryStatusOverride(telemetries: CurrentTelemetry[]): CurrentTelemetry[] {
    if (!telemetries) return [];
    // previously this would set telemetry status to the same state as the last received notification for that telemetry
    // new logic: assign notifications to telemetry object and let the telemetry object decide how to output the status
    return telemetries.map((telemetry) => {
      if (!telemetry) return null;
      telemetry.notifications = this.notifications.filter(
        (noti) =>
          noti.sensorID === telemetry.sensor.sensorID &&
          (noti.measurementTypeID === telemetry.measurementTypeID ||
            (noti.measurementTypeID === INACTIVE_LEAKAGE_ID &&
              telemetry.measurementTypeID === DIRECT_C_LEAKAGE_ID) || // inactive edge case direct-c
            (noti.measurementTypeID === INACTIVE_STEAM_TRAP_ID &&
              telemetry.measurementTypeID === STEAM_TRAP_STATUS_ID) || // inactive edge case steam trap
            (noti.measurementTypeID === INACTIVE_STEAM_TRAP_ANALYTICS_ID &&
              telemetry.measurementTypeID === STEAM_TRAP_STATUS_ID)) // inactive edge case steam trap analytics
      );
      return telemetry;
    });
  }

  get SensorTileTelemetry() {
    if (!this.sensors || this.sensors.length === 0) return [];
    let telemetry: CurrentTelemetry[] = this.sensors
      .map((sensor: Sensor) => sensor.telemetry)
      .flat(2);
    if (this.steamTrap.length > 0) {
      // special case - steam traps:
      if (
        !!telemetry.find(
          (item: CurrentTelemetry) => item.measurementTypeID === 1068
        )
      ) {
        let temperature = telemetry.filter(
          (item: CurrentTelemetry) => item.measurementTypeID === 1073
        );
        telemetry = telemetry
          .filter((item: CurrentTelemetry) => item.measurementTypeID === 1068)
          .map((item) =>
            Object.assign(
              new TypedSensorTelemetry<number, string>(item.sensor),
              item
            )
          );
        if (!!temperature && temperature.length > 0)
          telemetry.map((item) => {
            item['value'] = JSON.parse(JSON.stringify(item['value']));
            item['value'].timestamp = temperature[0]['value'].timestamp;
          });
      } else {
        // use inlet temp instead, if no analytics data available
        // outlet temp will be added to tile in AssetOverviewComponent
        telemetry = telemetry.filter(
          (item: CurrentTelemetry) => item.measurementTypeID === 1073
        );
      }
      /* .find(
          (item: CurrentTelemetry) =>
            item.measurementTypeID === 1073 || item.measurementTypeID === 1074
        )*/
    } else if (
      telemetry.find(
        (item: CurrentTelemetry) => item.measurementTypeID === 1048
      )
    ) {
      // special case - direct c, leakage
      telemetry = telemetry.filter(
        (item: CurrentTelemetry) => item.measurementTypeID === 1048
      );
    }
    return groupBy(telemetry, 'title').map((_telemetry) => {
      return {
        title: _telemetry[0].title,
        icon: _telemetry[0].svg,
        telemetry: _telemetry,
      };
    });
  }
  getSensorDetailTelemetry(sensorid: number) {
    if (!this.sensors || this.sensors.length === 0) return [];
    let telemetry: CurrentTelemetry[] = this.sensors
      .filter((sensor) => sensor.sensorID === sensorid)
      .map((sensor: Sensor) => sensor.telemetry)
      .flat(2);
    if (this.steamTrap.length > 0) {
      // special case - steam traps:
      const status = telemetry.find(
        (item) => item.measurementTypeID === STEAM_TRAP_STATUS_ID
      );
      if (!!status) {
        telemetry.map((item) => {
          if (item.measurementTypeID !== STEAM_TRAP_STATUS_ID) {
            item.critical = status.critical;
            item.warning = status.warning;
          }
        });
      }
      telemetry = telemetry.filter(
        (item: CurrentTelemetry) => item.measurementTypeID === 1073 // || item.measurementTypeID === 1074
      );
    } else if (
      telemetry.find(
        (item: CurrentTelemetry) => item.measurementTypeID === 1048
      )
    ) {
      // special case - direct c, leakage
      telemetry = telemetry.filter(
        (item: CurrentTelemetry) => item.measurementTypeID === 1048
      );
    } else if (
      telemetry.find(
        (item: CurrentTelemetry) => item.measurementTypeID === 1026
      )
    ) {
      // special case - direct c, leakage
      telemetry = telemetry.filter(
        (item: CurrentTelemetry) => item.measurementTypeID === 1026
      );
    }

    return groupBy(telemetry, 'title').map((_telemetry) => {
      return {
        title: _telemetry[0].title,
        icon: _telemetry[0].svg,
        telemetry: _telemetry,
      };
    });
  }

  public ApplyMeasurementSystem(
    measurementSystem: MeasurementSystemSettings
  ): void {
    this.sensors = this.sensors.map((sensor) => {
      sensor.telemetry = sensor.telemetry.map((telemetry: CurrentTelemetry) => {
        telemetry.ApplyMeasurementSystem(measurementSystem);
        return telemetry;
      });
      return sensor;
    });
    if (!!this.temperature) {
      this.temperature.ApplyMeasurementSystem(measurementSystem);
    }
    this.health = this.health.map((telemetry: CurrentTelemetry) => {
      telemetry.ApplyMeasurementSystem(measurementSystem);
      return telemetry;
    });
    this.steamTrap = this.steamTrap.map((telemetry: CurrentTelemetry) => {
      telemetry.ApplyMeasurementSystem(measurementSystem);
      return telemetry;
    });
  }
}

export class RotaingEquipmentStatus {
  public faultType: string;
  public lifePercentage: number;
  public healthStatus: string;
  public rul: number;
}

function groupBy<T>(arr: T[], key): T[][] {
  return Object.values(
    arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {})
  );
}
