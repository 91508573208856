<div
  [class]="'more-info body-bold ' + variant"
  [class.grouped]="grouped"
  [class.blackBg]="blackBg"
  [class.disabled]="disabled"
>
  <i
    nz-icon
    *ngIf="svg_icon"
    [nzType]="svg_icon"
    class="svg-icon"
    [class.fillwhite]="fillWhite"
  ></i>
  <span><ng-content></ng-content></span>
  <i
    nz-icon
    *ngIf="svg_icon_right"
    [nzType]="svg_icon_right"
    class="svg-icon right-icon"
    [class.fillwhite]="fillWhite"
  ></i>
</div>
