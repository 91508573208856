import * as convert from 'convert-units';
import { parseISO } from 'date-fns';

import { Notification } from './object';
import { MeasurementSystemSettings } from './settings';

const STEAM_TRAP_STATUS_ID = 1068;
const STEAM_TRAP_STEAM_LOSS_AMOUNT_ID = 1069;
const STEAM_TRAP_STEAM_LOSS_MASS_ID = 1070;
const STEAM_TRAP_STATUS_AGGREGATED_ID = 1075; // DEPRECTATED

export class Sensor {
  public sensorID: number;
  public sensorName: string;
  public junctionBoxSlot: string;
  public sensorDigitID: string;
  public edgeDigitID: string;
  public sensorStatus: string;
  public sensorStatusColor: string;

  public telemetry: CurrentTelemetry[];

  public isGhostSensor: boolean;

  public markerX: number;
  public markerY: number;
  public markerText: string;

  public inletNumber: string;
  public outletNumber: string;
  public DAU: string;
  public gateway: string;
  public geoPosition: string;

  get sensorDisplayLabel(): string {
    return (
      (this.markerText ? this.markerText : 'S-') +
      (this.sensorName ? ' | ' + this.sensorName : '')
    );
  }

  get Critical(): boolean {
    return (
      !!this.telemetry.find(
        (telemetry: CurrentTelemetry) => telemetry.critical
      ) && !this.Inactive
    );
  }
  get Warning(): boolean {
    return (
      !!this.telemetry.find(
        (telemetry: CurrentTelemetry) => telemetry.warning
      ) &&
      !this.Critical &&
      !this.Inactive
    );
  }
  get Inactive(): boolean {
    return !!this.telemetry.find(
      (telemetry: CurrentTelemetry) => telemetry.inactive
    );
  }
  get NoData(): boolean {
    return !this.telemetry.find((telemetry: CurrentTelemetry) =>
      isValidDate(telemetry.Timestamp)
    );
  }
}

function isValidDate(d: any) {
  return d instanceof Date && !isNaN(+d);
}

export class CurrentTelemetry {
  public unit: string;
  public title: string;
  public measurementTypeID: number;
  public svg: string;
  public valueType: 'numerical' | 'boolean' | 'flag' | 'minmax';

  public notifications: Notification[] = [];

  public get CurrentNotification(): Notification {
    if (this.notifications.length === 0) return null;
    return this.notifications.sort(
      (a, b) =>
        new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime()
    )[0];
  }

  private _warning: boolean = false; // if this is true, we don't need a notification to display as warning
  public get warning(): boolean {
    const notification = this.CurrentNotification;
    if (!notification) return this._warning;
    return (
      (notification.isWarning || this._warning) &&
      !notification.isCritical &&
      !notification.inactive
    );
  }

  private _critical: boolean = false; // if this is true, we don't need a notification to display as critical
  public get critical(): boolean {
    const notification = this.CurrentNotification;
    if (!notification) return this._critical;
    return (
      (notification.isCritical || this._critical) && !notification.inactive
    );
  }

  private _inactive: boolean = false; // if this is true, we don't need a notification to display as inactive
  public get inactive(): boolean {
    const notification = this.CurrentNotification;
    if (!notification) return this._inactive;
    return notification.inactive || this._inactive;
  }

  public set warning(value: boolean) {
    this._warning = value;
  }

  public set critical(value: boolean) {
    this._critical = value;
  }

  public set inactive(value: boolean) {
    this._inactive = value;
  }

  public recommendation: string;
  public workingMessage: string;
  public warningMessage: string;
  public criticalMessage: string;
  public inactiveMessage: string;
  public preload: boolean = false;
  public page: string = '';
  get Value(): { val: any; valStr: any; timestamp: Date }[] {
    return this['value'];
  }
  get Timestamp(): Date {
    return parseISO(this['value'].timestamp);
  }
  constructor(public sensor: Sensor) {}

  public ApplyMeasurementSystem(
    measurementSystem: MeasurementSystemSettings
  ): void {
    const new_unit =
      measurementSystem.abbreviation === 'im'
        ? convertUnitToImperial(this.unit)
        : convertUnitToMetric(this.unit);
    //if (new_unit !== this.unit) {
    if (this.valueType === 'numerical') {
      if (!!this['value'].map) {
        this['value'] = this['value'].map(
          (val: { val: any; valStr: any; timestamp: Date }) => {
            val.val = convertValue(val.val, this.unit, new_unit);
            val.valStr = val.val + new_unit;
            return val;
          }
        );
      } else {
        const new_val = convertValue(this['value'].val, this.unit, new_unit);
        this['value'] = {
          val: new_val,
          valStr: new_val + new_unit,
          timestamp: this['value'].timestamp,
        };
      }
    } else if (this.valueType === 'minmax') {
      if (!!this['value'].map) {
        this['value'] = this['value'].map(
          (val: { val: any; valStr: any; timestamp: Date }) => {
            val.val = {
              min: convertValue(val.val.min, this.unit, new_unit),
              max: convertValue(val.val.max, this.unit, new_unit),
            };
            val.valStr = {
              min: val.val.min + new_unit,
              max: val.val.max + new_unit,
            };
            return val;
          }
        );
      } else {
        this['value'] = {
          val: {
            min: convertValue(this['value'].val.min, this.unit, new_unit),
            max: convertValue(this['value'].val.max, this.unit, new_unit),
          },
          valStr: {
            min:
              convertValue(this['value'].val.min, this.unit, new_unit) +
              new_unit,
            max:
              convertValue(this['value'].val.max, this.unit, new_unit) +
              new_unit,
          },
          timestamp: this['value'].timestamp,
        };
      }
    }
    this.unit = new_unit;
    //}

    /*
      if (this.unit === '°C') {
        this.unit = '°F';
        if (this.valueType === 'numerical') {
          if (!!this['value'].map) {
            this['value'] = this['value'].map(
              (val: { val: any; valStr: any; timestamp: Date }) => {
                val.val = CelsiusToFahrenheit(val.val);
                val.valStr = val.val + this.unit;
                return val;
              }
            );
          } else {
            this['value'] = {
              val: CelsiusToFahrenheit(this['value'].val),
              valStr: this['value'].val + this.unit,
              timestamp: this['value'].timestamp,
            };
          }
        } else if (this.valueType === 'minmax') {
          if (!!this['value'].map) {
            this['value'] = this['value'].map(
              (val: { val: any; valStr: any; timestamp: Date }) => {
                val.val = {
                  min: CelsiusToFahrenheit(val.val.min),
                  max: CelsiusToFahrenheit(val.val.max),
                };
                val.valStr = {
                  min: val.val.min + this.unit,
                  max: val.val.max + this.unit,
                };
                return val;
              }
            );
          } else {
            this['value'] = {
              val: {
                min: CelsiusToFahrenheit(this['value'].val.min),
                max: CelsiusToFahrenheit(this['value'].val.max),
              },
              valStr: {
                min: CelsiusToFahrenheit(this['value'].val.min) + this.unit,
                max: CelsiusToFahrenheit(this['value'].val.max) + this.unit,
              },
              timestamp: this['value'].timestamp,
            };
          }
        }
      }*/
  }
}

function precision(a) {
  if (!isFinite(a)) return 0;
  let e = 1,
    p = 0;
  while (Math.round(a * e) / e !== a) {
    e *= 10;
    p++;
  }
  return p;
}

function RoundToPrecision(a, p) {
  // return +a.toFixed(p);
  return p <= 0
    ? Math.round(a)
    : Math.round(a * Math.pow(10, p)) / Math.pow(10, p);
}

function FahrenheitToCelsius(value: number) {
  return RoundToPrecision((value - 32) * 0.5556, precision(value));
}

function CelsiusToFahrenheit(value: number) {
  return RoundToPrecision(value * 1.8 + 32, precision(value));
}

function determineMeasurementType(unit: string): string {
  // length, area, volume, temperature, pressure,
  switch (unit.toLocaleLowerCase()) {
    case 'mm':
    case 'cm':
    case 'dm':
    case 'm':
    case 'km':
      return 'length';
    case 'mm²':
    case 'cm²':
    case 'dm²':
    case 'm²':
    case 'a':
    case 'ha':
    case 'km²':
      return 'area';
    case 'mm³':
    case 'cm³':
    case 'dm³':
    case 'l':
    case 'm³':
    case 'km³':
      return 'volume';
    case 'g':
    case 'kg':
      return 'mass';
    case '°c':
      return 'temperature';
    default:
      return '';
  }
}

function convertValue(value: number, old_unit: string, new_unit): number {
  const measurementType = determineMeasurementType(old_unit);
  switch (measurementType) {
    case 'length':
    case 'area':
    case 'volume':
    case 'mass':
    case 'temperature':
      const a: any = old_unit.replace('°', '');
      const b: any = new_unit.replace('°', '');
      return Math.round(convert(value).from(a).to(b) * 10) / 10;
      break;
    default:
      return value;
  }
}

function convertUnitToImperial(unit: string) {
  if (!unit || !unit.toLocaleLowerCase) return unit;
  switch (unit.toLocaleLowerCase().replace(' ', '')) {
    case 'mm':
      return 'in';
    case 'cm':
      return 'in';
    case 'dm':
      return 'in';
    case 'm':
      return 'ft';
    case 'km':
      return 'ml';
    case 'mm²':
      return 'in²';
    case 'cm²':
      return 'in²';
    case 'dm²':
      return 'in²';
    case 'm²':
      return 'ft²';
    case 'a':
      return 'ft²';
    case 'ha':
      return 'ft²';
    case 'km²':
      return 'ml²';
    case 'mm³':
      return 'in³';
    case 'cm³':
      return 'in³';
    case 'dm³':
      return 'in³';
    case 'l':
      return 'ft³';
    case 'm³':
      return 'ft³';
    case 'km³':
      return 'ml³';
    case 'g':
      return 'oz';
    case 'kg':
      return 'lb';
    case '°c':
      return '°F';
    default:
      return unit;
  }
}

function convertUnitToMetric(unit: string) {
  if (!unit || !unit.toLocaleLowerCase) return unit;
  switch (unit.toLocaleLowerCase().replace(' ', '')) {
    case 'in':
      return 'cm';
    case 'ft':
      return 'm';
    case 'ml':
      return 'km';
    case 'in²':
      return 'cm²';
    case 'ft²':
      return 'm²';
    case 'ml²':
      return 'km²';
    case 'in³':
      return 'cm³';
    case 'ft³':
      return 'm³';
    case 'ml³':
      return 'km³';
    case 'oz':
      return 'g';
    case 'lb':
      return 'kg';
    case '°f':
      return '°C';
    default:
      return unit;
  }
}

export class TypedSensorTelemetry<T, TStr> extends CurrentTelemetry {
  public value: { val: T; valStr: TStr; timestamp: Date };
}

export class RawSensorTelemetry {
  public sensorID: number;
  public sensorMeasurementTypeID: number;
  public sensorName: string;
  public junctionBoxSlot: string;
  public sensorDigitID: string;
  public edgeDigitID: string;
  public sensorMeasurementTypeDesignation: string;
  public value: number;
  public measuredValue: string;
  public valueType: string;
  public unit: string;
  public title: string;
  public svg: string;
  public minMaxSibling: number;
  public timestamp: Date;
  public sensorStatus: string;
  public sensorStatusColor: string;
  public objectStatusID: number;
  public objectStatus: string;
  public objectStatusColor: string;
  public warning: boolean;
  public critical: boolean;
  public inactive: boolean;
  public recommendation: string;
  public page: string;
  public workingMessage: string;
  public warningMessage: string;
  public inactiveMessage: string;
  public criticalMessage: string;
  public markerX: number;
  public markerY: number;
  public markerText: number;
  public inletNumber: string;
  public outletNumber: string;
  public dau: string;
  public gateway: string;
  public geoPosition: string;
}

export function ParseSensorsFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[]
): Sensor[] {
  const telemetryGroupedBySensor = groupBy<RawSensorTelemetry>(
    telemetry,
    'sensorID'
  );

  return telemetryGroupedBySensor.map((data: RawSensorTelemetry[]) => {
    const sensor = Object.assign(new Sensor(), {
      sensorID: data[0].sensorID,
      sensorName: data[0].sensorName,
      junctionBoxSlot: data[0].junctionBoxSlot,
      sensorDigitID: data[0].sensorDigitID,
      edgeDigitID: data[0].edgeDigitID,
      sensorStatus: data[0].sensorStatus,
      sensorStatusColor: data[0].sensorStatusColor,
      telemetry: null,
      health: null,
      isGhostSensor: false,
      markerX: data[0].markerX,
      markerY: data[0].markerY,
      markerText: data[0].markerText,
      inletNumber: data[0].inletNumber,
      outletNumber: data[0].outletNumber,
      DAU: data[0].dau,
      gateway: data[0].gateway,
      geoPosition: data[0].geoPosition,
    });
    sensor.telemetry = ParseSensorTelemetryFromRawSensorTelemetry(
      data,
      sensor
    ).filter((item: CurrentTelemetry) => item.measurementTypeID !== 1020);
    return sensor;
  });
}

export function ParseHealthFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[]
): CurrentTelemetry[] {
  const telemetryGroupedBySensor = groupBy<RawSensorTelemetry>(
    telemetry,
    'sensorID'
  );

  return telemetryGroupedBySensor
    .map((data: RawSensorTelemetry[]) => {
      const sensor = Object.assign(new Sensor(), {
        sensorID: data[0].sensorID,
        sensorName: data[0].sensorName,
        sensorStatus: data[0].sensorStatus,
        sensorStatusColor: data[0].sensorStatusColor,
        telemetry: null,
        health: null,
        isGhostSensor: false, // TODO
        geoPosition: data[0].geoPosition,
      });
      return ParseSensorHealthFromRawSensorTelemetry(data, sensor);
    })
    .flat(2)
    .filter(
      (item: CurrentTelemetry, index, array) =>
        index ===
        array.findIndex(
          (_item: CurrentTelemetry) =>
            _item.measurementTypeID === item.measurementTypeID
        )
    ); // filter out duplicate data (we only want one telemetry object per measurement type)
}

export function ParseSteamTrapFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[]
): CurrentTelemetry[] {
  const telemetryGroupedBySensor = groupBy<RawSensorTelemetry>(
    telemetry,
    'sensorID'
  );
  const output = telemetryGroupedBySensor
    .map((data: RawSensorTelemetry[]) => {
      const sensor = Object.assign(new Sensor(), {
        sensorID: data[0].sensorID,
        sensorName: data[0].sensorName,
        sensorStatus: data[0].sensorStatus,
        sensorStatusColor: data[0].sensorStatusColor,
        telemetry: null,
        health: null,
        isGhostSensor: false, // TODO
        geoPosition: data[0].geoPosition,
      });

      return (
        data
          .filter(
            (item) =>
              item.page === 'steam_trap' ||
              item.sensorMeasurementTypeID === 1079 ||
              item.sensorMeasurementTypeID === 1080
          )
          .map((item) => ParseTelemetry(item, telemetry, sensor, true))
          // set preload flag in order to pre load data on equipment overview page
          .filter((item) => !!item)
      ); // necessary because ParseTelemetry can return null in case of min-max-values
    })
    .flat(2);

  // steam trap status is coordinated by the predicted steam trap status measurement
  const status = output.find(
    (item) => item.measurementTypeID === STEAM_TRAP_STATUS_ID
  );
  if (!!status) {
    output.map((item) => {
      if (item.measurementTypeID !== STEAM_TRAP_STATUS_ID) {
        item.critical = status.critical;
        item.warning = status.warning;
      }
    });
  }
  return output;
}

export function ParseTemperatureFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[]
): CurrentTelemetry {
  const telemetryGroupedBySensor = groupBy<RawSensorTelemetry>(
    telemetry,
    'sensorID'
  );

  return telemetryGroupedBySensor
    .map((data: RawSensorTelemetry[]) => {
      const sensor = Object.assign(new Sensor(), {
        sensorID: data[0].sensorID,
        sensorName: data[0].sensorName,
        sensorStatus: data[0].sensorStatus,
        sensorStatusColor: data[0].sensorStatusColor,
        telemetry: null,
        health: null,
        isGhostSensor: false, // TODO
        geoPosition: data[0].geoPosition,
      });
      return ParseSensorTelemetryFromRawSensorTelemetry(data, sensor);
    })
    .flat(2)
    .find((item: CurrentTelemetry) => item.measurementTypeID === 1020);
}
/*
function ParseGroupedTelemetryFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[],
  sensor: Sensor
): Dictionary<SensorTelemetry> {
  const output = {};

  groupBy<RawSensorTelemetry>(telemetry, 'title').map((_telemetry) => {
    output[_telemetry[0].title] = _telemetry.map((item) =>
      ParseTelemetryFromRawSensorTelemetry(item, _telemetry, sensor)
    );
  });

  return new Dictionary(output);
}
*/

function ParseSensorTelemetryFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[],
  sensor: Sensor
): CurrentTelemetry[] {
  return telemetry
    .filter(
      (item) =>
        (!!item.page && item.page !== 'sensor_health') ||
        item.sensorMeasurementTypeID === 1078 || // include inactive measurements to display inactive status correctly
        item.sensorMeasurementTypeID === 1079 ||
        item.sensorMeasurementTypeID === 1080
    )
    .map((item) => ParseTelemetry(item, telemetry, sensor))
    .filter((item) => !!item); // necessary because ParseTelemetry can return null in case of min-max-values
}

function ParseSensorHealthFromRawSensorTelemetry(
  telemetry: RawSensorTelemetry[],
  sensor: Sensor
): CurrentTelemetry[] {
  return telemetry
    .filter((item) => item.page === 'sensor_health')
    .map((item) => ParseTelemetry(item, telemetry, sensor))
    .filter((item) => !!item); // necessary because ParseTelemetry can return null in case of min-max-values
}

function ParseTelemetry(
  telemetry: RawSensorTelemetry,
  telemetryList: RawSensorTelemetry[],
  sensor: Sensor,
  preload: boolean = false
): CurrentTelemetry {
  const metadata = {
    unit: telemetry.unit,
    title: telemetry.title,
    measurementTypeID: telemetry.sensorMeasurementTypeID,
    svg: telemetry.svg,
    warning: telemetry.warning,
    critical: telemetry.critical,
    inactive: telemetry.inactive,
    recommendation: telemetry.recommendation,
    workingMessage: telemetry.workingMessage,
    warningMessage: telemetry.warningMessage,
    inactiveMessage: telemetry.inactiveMessage,
    criticalMessage: telemetry.criticalMessage,
    valueType: telemetry.valueType,
    page: telemetry.page,
    preload: preload,
  };
  //inactive telemetry
  metadata.inactive = !!telemetryList.find(
    (telemetry) =>
      (telemetry.sensorMeasurementTypeID === 1078 ||
        telemetry.sensorMeasurementTypeID === 1079 ||
        telemetry.sensorMeasurementTypeID === 1080 ||
        telemetry.sensorMeasurementTypeID === 1082) &&
      telemetry.critical
  );

  // min-max telemetry
  if (telemetry.minMaxSibling !== null) {
    const sibling = telemetryList.find(
      (item) => telemetry.minMaxSibling === item.sensorMeasurementTypeID
    );
    if (!!sibling) {
      let siblingIsMin = sibling.value < telemetry.value;
      if (sibling.value === telemetry.value)
        siblingIsMin =
          sibling.sensorMeasurementTypeID < telemetry.sensorMeasurementTypeID;
      // avoid parsing min-max-values twice:
      if (
        siblingIsMin &&
        telemetry.sensorMeasurementTypeID !== 1026 &&
        telemetry.sensorMeasurementTypeID !== 1073
      )
        return null;

      const _value = {
        val: {
          min: telemetry.value,
          max: sibling.value,
        },
        valStr: {
          min: telemetry.measuredValue,
          max: sibling.measuredValue,
        },
        timestamp: telemetry.timestamp,
      };
      return Object.assign(
        new TypedSensorTelemetry<
          { min: number; max: number },
          { min: string; max: string }
        >(sensor),
        {
          ...metadata,
          valueType: 'minmax',
          value: _value,
        }
      );
    }
  }

  // numerical telemetry

  const value = {
    val: telemetry.value,
    valStr: telemetry.measuredValue,
    timestamp: telemetry.timestamp,
  };

  return Object.assign(new TypedSensorTelemetry<number, string>(sensor), {
    ...metadata,
    valueType: metadata.valueType ? metadata.valueType : 'numerical',
    value: value,
  });

  // boolean telemetry
  // TODO
}

function groupBy<T>(arr: T[], key): T[][] {
  return Object.values(
    arr.reduce(function (rv, x) {
      (rv[x[key]] = rv[x[key]] || []).push(x);
      return rv;
    }, {})
  );
}

// next steps:
//  - [ ] output this structure in GetAssetV2()
//  - [ ] utilize in AssetDetail Class / Asset Overview Component
