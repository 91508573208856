<app-header [transparent]="false" [title]="getTitle()"></app-header>

<!-- =============================== Profile =============================== -->

<div
  *ngIf="url === 'profile'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>

  <div class="toast success" *ngIf="success">
    <i
      nz-icon
      class="inline-error-icon multicolorIcon"
      nzType="icons:check-circle-filled"
    ></i>
    <span>{{ i18n.string("changes_successful") }}</span>
  </div>

  <div class="toast error" *ngIf="error">
    <i
      nz-icon
      class="inline-error-icon fillwhite"
      nzType="icons:error-exclamation-circle"
    ></i>
    <span>{{ i18n.string("changes_problem") }}</span>
  </div>

  <h2>{{ i18n.string("personal_information") }}</h2>

  <form #profileForm *ngIf="settingsCopyForProfile">
    <div class="profile-label-group">
      <app-input-label
        [label]="i18n.string('name')"
        [key]="'firstname'"
        [value]="settingsCopyForProfile.name"
        (onchange)="onNameChange($event)"
      >
      </app-input-label>
      <app-input-label
        [label]="i18n.string('surname')"
        [key]="'lastname'"
        [value]="settingsCopyForProfile.surname"
        (onchange)="onSurnameChange($event)"
      ></app-input-label>
      <app-input-label
        [label]="i18n.string('designation')"
        [key]="'designation'"
        [value]="settingsCopyForProfile.designation"
        [optional]="true"
        (onchange)="onDesignationChange($event)"
      >
      </app-input-label>
    </div>

    <app-nav-button
      [primary]="true"
      svg_icon="icons:save"
      (click)="onSubmitProfileChanges()"
      [disabled]="submitDisabled()"
    >
      {{ i18n.string("apply_changes") }}</app-nav-button
    >
  </form>

  <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
</div>

<div
  *ngIf="url === 'change-password'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <app-change-pw-form></app-change-pw-form>
</div>

<!-- =============================== Language =============================== -->

<div
  *ngIf="url === 'language'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>

  <div class="toast success" *ngIf="success">
    <i
      nz-icon
      class="inline-error-icon multicolorIcon"
      nzType="icons:check-circle-filled"
    ></i>
    <span>{{ i18n.string("changes_successful") }}</span>
  </div>

  <div class="toast error" *ngIf="error">
    <i
      nz-icon
      class="inline-error-icon fillwhite"
      nzType="icons:error-exclamation-circle"
    ></i>
    <span>{{ i18n.string("changes_problem") }}</span>
  </div>

  <nz-radio-group
    [(ngModel)]="settings.languageID"
    *ngIf="!loading && !!settings"
  >
    <div
      class="radio-input-wrapper"
      *ngFor="let language of settings.languages"
      (click)="settings.languageID = language.id"
    >
      <label class="body-bold" nz-radio [nzValue]="language.id">{{
        language.name
      }}</label>
    </div>
  </nz-radio-group>
  <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
</div>

<!-- =============================== Measurement & Metric =============================== -->

<div
  *ngIf="url === 'metric'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>

  <div class="toast success" *ngIf="success">
    <i
      nz-icon
      class="inline-error-icon multicolorIcon"
      nzType="icons:check-circle-filled"
    ></i>
    <span>{{ i18n.string("changes_successful") }}</span>
  </div>

  <div class="toast error" *ngIf="error">
    <i
      nz-icon
      class="inline-error-icon fillwhite"
      nzType="icons:error-exclamation-circle"
    ></i>
    <span>{{ i18n.string("changes_problem") }}</span>
  </div>

  <h2>{{ i18n.string("system_of_measurement") }}</h2>

  <nz-radio-group
    [(ngModel)]="settings.measurementSystemID"
    *ngIf="!loading && settings"
  >
    <div
      class="radio-input-wrapper"
      *ngFor="let measurement_system of settings.measurementSystems"
      (click)="settings.measurementSystemID = measurement_system.id"
    >
      <label class="body-bold" nz-radio [nzValue]="measurement_system.id">{{
        i18n.parse(measurement_system.name)
      }}</label>
    </div>
  </nz-radio-group>
  <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>

  <h2>{{ i18n.string("date_format") }}</h2>

  <nz-radio-group
    [(ngModel)]="settings.dateFormatID"
    *ngIf="!loading && settings"
  >
    <div
      class="radio-input-wrapper"
      *ngFor="let date_format of settings.dateFormats"
      (click)="settings.dateFormatID = date_format.id"
    >
      <label class="body-bold" nz-radio [nzValue]="date_format.id">{{
        date_format.name
      }}</label>
    </div>
  </nz-radio-group>
  <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
</div>

<!-- =============================== Appearance =============================== -->

<div
  *ngIf="url === 'appearance'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>

  <div class="toast success" *ngIf="success">
    <i
      nz-icon
      class="inline-error-icon multicolorIcon"
      nzType="icons:check-circle-filled"
    ></i>
    <span>{{ i18n.string("changes_successful") }}</span>
  </div>

  <div class="toast error" *ngIf="error">
    <i
      nz-icon
      class="inline-error-icon fillwhite"
      nzType="icons:error-exclamation-circle"
    ></i>
    <span>{{ i18n.string("changes_problem") }}</span>
  </div>

  <div class="appearance-wrapper">
    <nz-radio-group
      [(ngModel)]="settings.themeID"
      *ngIf="!loading && !!settings"
    >
      <div
        class="radio-input-wrapper"
        *ngFor="let theme of settings.themes"
        (click)="settings.themeID = theme.id"
      >
        <img [src]="'assets/images/' + theme.image" />
        <label class="body-bold" nz-radio [nzValue]="theme.id">{{
          i18n.parse(theme.name)
        }}</label>
      </div>
    </nz-radio-group>
    <nz-spin nzSimple [nzSize]="'large'" *ngIf="loading"></nz-spin>
  </div>
</div>

<!-- =============================== Notifications =============================== -->

<div
  *ngIf="url === 'notifications'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <!-- =============================== Show all Notifications =============================== -->
  <app-all-notifications-settings
    [settings]="settings"
    (updateSettings)="refresh()"
    [success]="success"
    [loading]="loading"
  >
  </app-all-notifications-settings>

  <!-- =============================== Show Individual Notifications =============================== -->
  <app-notifications-settings-subpage
    [settings]="settings"
    [error]="error"
    [success]="success"
    [loading]="loading"
  >
  </app-notifications-settings-subpage>
</div>

<!-- =============================== Technical documents =============================== -->
<div
  *ngIf="url === 'technical_documents'"
  class="content desktop-slim"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>

  <app-technical-documents-subpage [page]="firstParameter">
  </app-technical-documents-subpage>
</div>

<!-- =============================== Help & FAQ =============================== -->

<div
  *ngIf="url === 'help'"
  class="content desktop-medium"
  [class.desktop]="!isMobile"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <div class="more-info-subgroup terms-subgroup helpfaq-subgroup">
    <app-more-info
      [variant]="'more-info-flat'"
      [grouped]="true"
      svg_icon="icons:sensor"
      svg_icon_right="icons:arrow-right"
      (click)="toAboutPage()"
      >{{ i18n.string("about_loctite") }}
    </app-more-info>
    <app-more-info
      [variant]="'more-info-flat'"
      *ngIf="false"
      [grouped]="true"
      svg_icon="icons:help-round"
      svg_icon_right="icons:arrow-right"
      (click)="toSubpage('help/faq')"
      >{{ i18n.string("faq") }}
    </app-more-info>
  </div>

  <h2>{{ i18n.string("your_contact") }}</h2>
  <div class="contact-outer-wrapper-flex">
    <img
      nz-image
      nzDisablePreview
      class="employee-img"
      nzSrc="/assets/images/support.jpg"
      alt="Employee"
      nzFallback="assets\images\support.jpg"
    />
    <div class="contact-wrapper-flex">
      <!--<h4 *ngIf="false">Helen Smith</h4>
      <span *ngIf="false">Public Relationship</span>-->
      <div class="button-wrapper-contact first-button">
        <app-nav-button
          [primary]="true"
          svg_icon="icons:mail"
          mailto="service@loctite-pulse.com"
        >
          service@loctite-pulse.com</app-nav-button
        >
      </div>
      <div class="button-wrapper-contact" *ngIf="false">
        <app-nav-button
          [primary]="true"
          svg_icon="icons:phone"
          phone="+49893208001600"
          >+49 89 320 800 1600
        </app-nav-button>
      </div>
    </div>
  </div>
</div>

<!-- =============================== Help: About Loctite  =============================== -->

<div
  *ngIf="url === 'help/about'"
  class="content content-more-margin desktop-wide"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <div class="information-text-wrapper">
    <p class="body-bold">Lorem ipsum dolor sit amet.</p>
    <p>
      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit volutpat
      aliquet ultrices ultricies. Morbi amet pretium rhoncus lobortis ultricies.
      Ultrices dictum eget id sed id feugiat. Egestas laoreet ac sed posuere.
      <br /><br />
      Tincidunt leo, lectus blandit velit massa. In at ac volutpat suspendisse
      nulla non. Diam id arcu et accumsan. Sapien iaculis nullam sapien lectus
      dui cum ullamcorper tincidunt. Tincidunt lorem lorem aliquam, ut amet
      blandit ornare gravida amet. Id velit, non elementum, dui lobortis cursus
      eleifend. Egestas quam tincidunt praesent dictum placerat ultrices
      pulvinar. Eget pharetra venenatis egestas tristique sit quis enim. Magna
      at arcu massa nibh. Etiam lectus praesent ut nulla placerat amet.
      <br /><br />
      Magna in mauris, semper purus sed. Id iaculis duis tellus tristique nunc
      venenatis condimentum egestas non. Felis condimentum sed consectetur at
      nisl vitae integer risus malesuada. Viverra ultrices bibendum sed iaculis
      lectus vel tristique.
    </p>
  </div>
</div>

<!-- =============================== Help: FAQ =============================== -->

<div
  *ngIf="url === 'help/faq'"
  class="content desktop-medium"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <nz-collapse [nzBordered]="false" id="settings-faq-collapse">
    <nz-collapse-panel
      #pA
      [nzHeader]="collapseHeaderA"
      [nzActive]="faqQuestions[0]"
      [nzExtra]="collapseIconA"
      nzShowArrow="false"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi
        faucibus in enim lobortis tortor ligula enim et pretium. Eleifend
        aliquam est dictumst sagittis. Posuere elementum elit aliquet tincidunt.
        Sem id quis aliquet aliquet.
      </p>
      <ng-template #collapseIconA let-active>
        <i
          nz-icon
          *ngIf="!pA.nzActive"
          [nzType]="'icons:plus'"
          class="collapse-icon"
        ></i>
        <i
          nz-icon
          *ngIf="pA.nzActive"
          [nzType]="'icons:minus'"
          class="collapse-icon"
        ></i>
      </ng-template>
      <ng-template #collapseHeaderA>
        <span class="body-bold">{{ i18n.string("q_change_appearance") }}</span>
      </ng-template>
    </nz-collapse-panel>
    <nz-collapse-panel
      #pB
      [nzHeader]="collapseHeaderB"
      [nzActive]="faqQuestions[1]"
      [nzExtra]="collapseIconB"
      nzShowArrow="false"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi
        faucibus in enim lobortis tortor ligula enim et pretium. Eleifend
        aliquam est dictumst sagittis. Posuere elementum elit aliquet tincidunt.
        Sem id quis aliquet aliquet.
      </p>
      <ng-template #collapseIconB let-active>
        <i
          nz-icon
          *ngIf="!pB.nzActive"
          [nzType]="'icons:plus'"
          class="collapse-icon"
        ></i>
        <i
          nz-icon
          *ngIf="pB.nzActive"
          [nzType]="'icons:minus'"
          class="collapse-icon"
        ></i>
      </ng-template>
      <ng-template #collapseHeaderB>
        <span class="body-bold">{{ i18n.string("q_document_problem") }}</span>
      </ng-template>
    </nz-collapse-panel>
    <nz-collapse-panel
      #pC
      [nzHeader]="collapseHeaderC"
      [nzActive]="faqQuestions[2]"
      style="border: none"
      [nzExtra]="collapseIconC"
      nzShowArrow="false"
    >
      <p>
        Lorem ipsum dolor sit amet, consectetur adipiscing elit. Facilisi
        faucibus in enim lobortis tortor ligula enim et pretium. Eleifend
        aliquam est dictumst sagittis. Posuere elementum elit aliquet tincidunt.
        Sem id quis aliquet aliquet.
      </p>
      <ng-template #collapseIconC let-active>
        <i
          nz-icon
          *ngIf="!pC.nzActive"
          [nzType]="'icons:plus'"
          class="collapse-icon"
        ></i>
        <i
          nz-icon
          *ngIf="pC.nzActive"
          [nzType]="'icons:minus'"
          class="collapse-icon"
        ></i>
      </ng-template>
      <ng-template #collapseHeaderC>
        <span class="body-bold">{{ i18n.string("q_sensor_health") }}</span>
      </ng-template>
    </nz-collapse-panel>
  </nz-collapse>
</div>

<!-- =============================== Terms (Overview) =============================== -->

<!--<div *ngIf="url === 'terms'" class="content desktop-slim" [class.desktop]="!isMobile">
  <div class="more-info-subgroup terms-subgroup">
    <app-more-info [variant]="'more-info-flat'" [grouped]="true" svg_icon="icons:terms"
      svg_icon_right="icons:arrow-right" (click)="toTermsOfService()">{{i18n.string('terms_of_service')}}
    </app-more-info>
    <app-more-info [variant]="'more-info-flat'" *ngIf="false" [grouped]="true" svg_icon="icons:privacy"
      svg_icon_right="icons:arrow-right" (click)="toSubpage('terms/privacypolicy')">{{i18n.string('privacy_policy')}}
    </app-more-info>
    <app-more-info [variant]="'more-info-flat'" [grouped]="true" svg_icon="icons:imprint"
      svg_icon_right="icons:arrow-right" (click)="toImprint()">{{i18n.string('imprint2')}}</app-more-info>
  </div>
</div>-->

<!-- =============================== Terms: Terms of Service =============================== -->

<div
  *ngIf="url === 'terms/termsofservice'"
  class="content content-more-margin desktop-wide"
  [class.desktop]="!isMobile"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <p class="body-bold">Lorem ipsum dolor sit amet.</p>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit volutpat
    aliquet ultrices ultricies. Morbi amet pretium rhoncus lobortis ultricies.
    Ultrices dictum eget id sed id feugiat. Egestas laoreet ac sed posuere.
    <br /><br />
    Tincidunt leo, lectus blandit velit massa. In at ac volutpat suspendisse
    nulla non. Diam id arcu et accumsan. Sapien iaculis nullam sapien lectus dui
    cum ullamcorper tincidunt. Tincidunt lorem lorem aliquam, ut amet blandit
    ornare gravida amet. Id velit, non elementum, dui lobortis cursus eleifend.
    Egestas quam tincidunt praesent dictum placerat ultrices pulvinar. Eget
    pharetra venenatis egestas tristique sit quis enim. Magna at arcu massa
    nibh. Etiam lectus praesent ut nulla placerat amet.
    <br /><br />
    Magna in mauris, semper purus sed. Id iaculis duis tellus tristique nunc
    venenatis condimentum egestas non. Felis condimentum sed consectetur at nisl
    vitae integer risus malesuada. Viverra ultrices bibendum sed iaculis lectus
    vel tristique.
  </p>
</div>

<!-- =============================== Terms: Privacy Policy =============================== -->

<div
  *ngIf="url === 'terms/privacypolicy'"
  class="content content-more-margin desktop-wide"
  [class.desktop]="isDesktop"
>
  <h1>{{ getTitle() }}</h1>
  <p class="body-bold">Lorem ipsum dolor sit amet.</p>
  <p>
    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Velit volutpat
    aliquet ultrices ultricies. Morbi amet pretium rhoncus lobortis ultricies.
    Ultrices dictum eget id sed id feugiat. Egestas laoreet ac sed posuere.
    <br /><br />
    Tincidunt leo, lectus blandit velit massa. In at ac volutpat suspendisse
    nulla non. Diam id arcu et accumsan. Sapien iaculis nullam sapien lectus dui
    cum ullamcorper tincidunt. Tincidunt lorem lorem aliquam, ut amet blandit
    ornare gravida amet. Id velit, non elementum, dui lobortis cursus eleifend.
    Egestas quam tincidunt praesent dictum placerat ultrices pulvinar. Eget
    pharetra venenatis egestas tristique sit quis enim. Magna at arcu massa
    nibh. Etiam lectus praesent ut nulla placerat amet.
    <br /><br />
    Magna in mauris, semper purus sed. Id iaculis duis tellus tristique nunc
    venenatis condimentum egestas non. Felis condimentum sed consectetur at nisl
    vitae integer risus malesuada. Viverra ultrices bibendum sed iaculis lectus
    vel tristique.
  </p>
</div>

<!-- =============================== Terms: Imprint =============================== -->

<div
  *ngIf="url === 'imprint'"
  class="content content-more-margin-view desktop-wide"
  [class.desktop]="isDesktop"
>
  <app-imprint></app-imprint>
</div>

<div
  *ngIf="url === 'data-protection'"
  class="content content-more-margin-view desktop-wide"
  [class.desktop]="isDesktop"
>
  <app-data-protection></app-data-protection>
</div>

<div
  *ngIf="url === 'service-conditions'"
  class="content content-more-margin-view desktop-wide"
  [class.desktop]="!isMobile"
>
  <app-service-conditions></app-service-conditions>
</div>

<div
  *ngIf="url === 'terms-of-use'"
  class="content content-more-margin-view desktop-wide"
  [class.desktop]="isDesktop"
>
  <app-terms-of-use></app-terms-of-use>
</div>

<div
  *ngIf="url === 'note-for-us-residents'"
  class="content content-more-margin-view desktop-wide"
  [class.desktop]="isDesktop"
>
  <app-note-for-us-residents></app-note-for-us-residents>
</div>
