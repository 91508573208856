<div class="all-notification-container" *ngIf="showAllNotification">
  <app-email-toggle
    [settings]="settings"
    [loading]="loading"
    [userEmail]="UserEmail"
    [(value)]="WarningNotificationsEmail"
    [allNotifications]="true"
  ></app-email-toggle>

  <app-push-notification-toggle
    [settings]="settings"
    [loading]="loading"
    [(value)]="WarningNotificationsPush"
    [allNotifications]="true"
  ></app-push-notification-toggle>
</div>

<nz-modal
  [(nzVisible)]="pushNotificationModalVisible"
  (nzOnCancel)="closePushNotificationModal(false)"
  (nzOnClose)="closePushNotificationModal(false)"
  [nzFooter]="null"
  [nzCloseIcon]="'icons:exit'"
  [nzTitle]="i18n.string('push_notification')"
>
  <p>{{ i18n.string("push_notification_modal_text") }}</p>
  <ng-container *ngIf="!requestingNotificationPermission">
    <app-asset-nav-button
      (click)="requestNotificationPermission()"
      blackBG="true"
      svg_icon_right=""
      [center]="true"
      [bypassConnectedStatus]="true"
      >{{ i18n.string("always") }}
    </app-asset-nav-button>
    <app-asset-nav-button
      id="never-button"
      svg_icon_right=""
      [center]="true"
      [bypassConnectedStatus]="true"
      (click)="closePushNotificationModal(false)"
      >{{ i18n.string("never") }}
    </app-asset-nav-button>
  </ng-container>
  <app-spinner
    [black]="true"
    *ngIf="requestingNotificationPermission"
    class="grant-permission-spinner"
  ></app-spinner>
</nz-modal>

<nz-modal
  [(nzVisible)]="!!customAlertMessage"
  (nzOnCancel)="closeCustomAlert()"
  (nzOnClose)="closePushNotificationModal(false)"
  [nzFooter]="null"
  [nzCloseIcon]="'icons:exit'"
  [nzTitle]="customAlertTitle"
>
  <p>{{ customAlertMessage }}</p>
  <app-asset-nav-button
    *ngIf="instructionsAddToHomeScreenIOS"
    (click)="DisplayAddToHomeScreenIOSInstructions()"
    blackBG="true"
    svg_icon_right=""
    [center]="true"
    [bypassConnectedStatus]="true"
    >{{ i18n.string("instructions") }}
  </app-asset-nav-button>
  <app-asset-nav-button
    (click)="closeCustomAlert()"
    blackBG="true"
    svg_icon_right=""
    [center]="true"
    [bypassConnectedStatus]="true"
    >{{ i18n.string("okay") }}
  </app-asset-nav-button>
</nz-modal>